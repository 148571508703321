interface ReferralActivityData {
    created_at: Date,
    type: ReferralEventType,
    customer_id: string,
    customer_name: string,
    sharedUrl?: string,
    ip_guest?: string
}

interface ReferralActivityBase extends ReferralActivityData {
    id: string
}

class ReferralActivityBase {
    constructor(id: string, data: ReferralActivityData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): ReferralActivityData {
        const { id, ...data } = this;
        return data;
    }
}

enum ReferralEventType {
    SharedUrl = "sharedUrl",
    VisitUrl = "visitUrl",
    BookDog = "bookDog",
    Paid = "paid",
}

export { ReferralActivityBase, ReferralEventType };
export type { ReferralActivityData };