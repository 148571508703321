import { useState } from "react";
import { Box, FormControlLabel, FormGroup } from "@mui/material";
import { Customer } from "@marathon/client-side/entities/Customer";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";

interface Props {
    customer: Customer,
    handleUpdateConsent: (dogsTextOk: boolean, marketingTextOk: boolean, marketingEmailOk: boolean, postcardOk: boolean) => Promise<void>,
    isUpdatingConsent: boolean
}

const NotificationSettings = ({ customer, handleUpdateConsent, isUpdatingConsent }: Props) => {
    const [dogsTextOk, setDogsTextOk] = useState<boolean>(customer.text_ok ?? false);
    const [marketingTextOk, setMarketingTextOk] = useState<boolean>(!customer.marketing_sms_opt_out);
    const [marketingEmailOk, setMarketingEmailOk] = useState<boolean>(!customer.marketing_email_opt_out);
    const [postcardOk, setPostcardOk] = useState<boolean>(!customer.postcard_opt_out);
    const classes = useStyles();

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Notification Settings" />
            <ContentWrapper>
                <FormGroup style={{ textAlign: "left" }}>
                    <FormControlLabel
                        style={{ width: "100%" }}
                        control={
                            <Box>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={dogsTextOk}
                                        onChange={e => setDogsTextOk(e.target.checked)}
                                    />
                                    <span className="check"></span>
                                </label>
                            </Box>
                        }
                        label={
                            <Box
                                fontSize="15px"
                                color="#000000"
                                textAlign="left"
                                lineHeight="normal"
                                ml={5}
                            >
                                It's ok to email or text me about my dog(s)
                            </Box>
                        }
                    />
                    <FormControlLabel
                        style={{ width: "100%", marginTop: 20 }}
                        control={
                            <Box>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={marketingTextOk}
                                        onChange={e => setMarketingTextOk(e.target.checked)}
                                    />
                                    <span className="check"></span>
                                </label>
                            </Box>
                        }
                        label={
                            <Box
                                fontSize="15px"
                                color="#000000"
                                textAlign="left"
                                lineHeight="normal"
                                ml={5}
                            >
                                Marketing texts are ok
                            </Box>
                        }
                    />
                    <FormControlLabel
                        style={{ width: "100%", marginTop: 20 }}
                        control={
                            <Box>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={marketingEmailOk}
                                        onChange={e => setMarketingEmailOk(e.target.checked)}
                                    />
                                    <span className="check"></span>
                                </label>
                            </Box>
                        }
                        label={
                            <Box
                                fontSize="15px"
                                color="#000000"
                                textAlign="left"
                                lineHeight="normal"
                                ml={5}
                            >
                                Marketing emails are ok
                            </Box>
                        }
                    /><FormControlLabel
                        style={{ width: "100%", marginTop: 20 }}
                        control={
                            <Box>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={postcardOk}
                                        onChange={e => setPostcardOk(e.target.checked)}
                                    />
                                    <span className="check"></span>
                                </label>
                            </Box>
                        }
                        label={
                            <Box
                                fontSize="15px"
                                color="#000000"
                                textAlign="left"
                                lineHeight="normal"
                                ml={5}
                            >
                                Postcards are ok
                            </Box>
                        }
                    />
                </FormGroup>
                <Button
                    showSpinner={isUpdatingConsent}
                    variant="contained"
                    className={classes.button}
                    disableElevation
                    onClick={async () => await handleUpdateConsent(dogsTextOk, marketingTextOk, marketingEmailOk, postcardOk)}
                    disabled={!customer.tos_pp_ok}
                >
                    Save
                </Button>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default NotificationSettings;