import { useEffect, useState, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import steps, { CustomStepSegment, getNumbersForCustomStep } from "@marathon/common/onlineBookingSteps";
import OnlineBookingStep1 from "./OnlineBookingStep1";
import OnlineBookingStep2 from "./OnlineBookingStep2";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { Loading } from "@marathon/web/components/Loading";
import { Appointment } from "@marathon/client-side/entities/Appointment";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Customer } from "@marathon/client-side/entities/Customer";
import { OnlineBookingFlowType, errorMessages } from "@marathon/common/constants";
import { useOnlineBookingContext } from "../OnlineBookingContext";
import { AppointmentOrOccurrence, Occurrence } from "@marathon/common/entities/Occurrence";
import OnlineBookingCodeVerification from "../OnlineBookingCodeVerification";
import { normalizePhone } from "@marathon/common/normalizationHelper";
import { authentication } from "@marathon/web/firebaseApp";
import mixpanel from "mixpanel-browser";
import { Credit } from "@marathon/client-side/entities/Credit";
import { OnlineBookingHeader } from "../OnlineBookingHeader";
import OnlineBookingInnerContainer from "./OnlineBookingInnerContainer";
import { EmailAuthProvider, isSignInWithEmailLink, linkWithCredential, signInWithEmailLink } from "firebase/auth";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { SecondaryAddress } from "@marathon/client-side/entities/SecondaryAddress";
import { DiscountCode } from "@marathon/client-side/entities/DiscountCode";
import { Recurrence } from "@marathon/client-side/entities/Recurrence";
import { AppointmentData } from "@marathon/common/entities/Appointment";

interface OnlineBookingRouteParams {
    stepNumber?: string;
    substepNumber?: string;
}

interface Props {
    customStep?: CustomStepSegment
}

export default function OnlineBookingContainer({ customStep }: Props) {
    const routeParams = useParams<OnlineBookingRouteParams>();
    const [stepNumber, setStepNumber] = useState(routeParams.stepNumber ? parseInt(routeParams.stepNumber) : steps.returning.initial);
    const [substepNumber, setSubstepNumber] = useState<number | null>(null);
    const [previousStepNumber, setPreviousStepNumber] = useState<number | null>(steps.returning.initial);
    const [backToCustomStep, setBackToCustomStep] = useState<CustomStepSegment | null>(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [pets, setPets] = useState<Pet[]>([]);
    const [selectedServices, setSelectedServices] = useState<{ petId: string, serviceId: string }[]>([]);
    const [customerCredits, setCustomerCredits] = useState<Credit[]>([]);
    const [customerUpcomingAppointments, setCustomerUpcomingAppointments] = useState<AppointmentOrOccurrence[]>([]);
    const [customerPastAppointments, setCustomerPastAppointments] = useState<AppointmentOrOccurrence[]>([]);
    const [customerRecurrences, setCustomerRecurrences] = useState<Recurrence[]>([]);
    const [selectedAppointment, setSelectedAppointment] = useState<AppointmentOrOccurrence | null>(null);
    const [selectedAddress, setSelectedAddress] = useState<CustomerAddressData | SecondaryAddress>();
    const [customerAddresses, setCustomerAddresses] = useState<(CustomerAddressData | SecondaryAddress)[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
    const [isPrefetchingCustomer, setIsPrefetchingCustomer] = useState(true);

    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [isBlockedUser, setIsBlockedUser] = useState(false);
    const [shortcutPhone, setShortcutPhone] = useState<string | null>(null);

    const [discountCode, setDiscountCode] = useState<DiscountCode>();

    const { onSignIn, isSignedIn, groomers } = useOnlineBookingContext();

    const history = useHistory();
    const location = useLocation();
    const { search } = useLocation();

    const goToStep = useCallback((step: number, substep?: number, shouldReplace?: boolean) => {
        const newUrl = substep
            ? `/online-booking/${step}/${substep}`
            : `/online-booking/${step}`;

        if (shouldReplace)
            history.replace(newUrl);
        else
            history.push(newUrl);

    }, [history]);

    const fetchCustomerInternal = useCallback(async () => {
        const currentCustomer = await CallableFunctions.public.getCurrentCustomer();
        setCustomer(currentCustomer?.customer || null);
        const petsTemp = currentCustomer?.pets.filter(x => !x.isDefaultDog()) || [];
        setPets(petsTemp);
        if (currentCustomer) {
            setSelectedServices(petsTemp.map(x => ({ petId: x.id, serviceId: x.getDefaultServiceId() })));
            setCustomerCredits(currentCustomer.credits.filter(x => !x.was_used));
            setCustomerPastAppointments((await Appointment.searchPreviousFromOnlineBooking()).slice(0, 10));
            setCustomerAddresses([currentCustomer.customer.address, ...currentCustomer.secondaryAddresses]);
            setSelectedAddress(currentCustomer.customer.address);
            await refreshAppointmentsAndRecurrences();
        }

        return currentCustomer;
    }, []);

    const authenticateCustomer = async () => {
        const normalizedPhone = shortcutPhone ? normalizePhone(shortcutPhone) : normalizePhone(phoneNumber);
        if (!normalizedPhone) {
            Swal.fire({ title: "Please enter a phone number" });
            return;
        }

        const matchingGroomer = groomers.find(x => x.phone === normalizedPhone);
        if (matchingGroomer) {
            Swal.fire({ icon: "error", title: errorMessages.phone_number_not_valid });
            return;
        }

        if (!authentication.currentUser)
            Sentry.setUser({ username: normalizedPhone });

        try {
            setIsLoadingCustomer(true);
            const existingCustomer = await CallableFunctions.public.getCustomerPublicInfo({ phone: normalizedPhone });
            setPhoneNumberError(!existingCustomer || !existingCustomer.isLocalized);
            setIsBlockedUser(!!existingCustomer?.blocked);
            if (!existingCustomer || !existingCustomer.isLocalized || existingCustomer.blocked)
                return;

            let isValidLoggedCustomer = false;
            if (isSignedIn
                && authentication.currentUser?.phoneNumber
                && normalizePhone(authentication.currentUser.phoneNumber) === normalizedPhone) {
                isValidLoggedCustomer = true;
            }
            else if (isSignedIn
                && authentication.currentUser?.email) {
                const customerInfoFromEmail = await CallableFunctions.public.getCustomerPublicInfo({ email: authentication.currentUser.email });
                if (customerInfoFromEmail
                    && normalizePhone(customerInfoFromEmail.phone ?? "") === normalizedPhone) {
                    isValidLoggedCustomer = true;
                }
            }

            if (isValidLoggedCustomer) {
                goToStep(steps.returning.welcome);
            }
            else {
                setCustomer(null);
                await onSignIn({ phoneNumber: normalizedPhone });
                goToStep(steps.returning.phoneInput, steps.returning.phoneInputSubsteps.codeVerification, true);
            }
        }
        catch (error) {
            Sentry.captureException(error);
        }
        finally {
            setIsLoadingCustomer(false);
        }
    };

    const refreshAppointmentsAndRecurrences = async () => {
        setCustomerUpcomingAppointments(await Appointment.searchFromOnlineBooking());
        setCustomerRecurrences(await Recurrence.searchFromOnlineBooking());
    };

    const handleCancelAppointment = async (appointmentToCancel: AppointmentOrOccurrence) => {
        try {
            if (appointmentToCancel.occurrenceIndex !== undefined || appointmentToCancel.occurrenceIndex === 0) {
                await Recurrence.cancelOccurrenceFromOnlineBooking(appointmentToCancel as Occurrence, "Client cancelled via Online Booking", customerRecurrences);
            } else {
                await Appointment.cancel(appointmentToCancel.id, "Client cancelled via Online Booking", true);
            }
            await refreshAppointmentsAndRecurrences();
        }
        catch (error) {
            Sentry.captureException(error);
        }
    };

    const handleUpdateAppointment = async (appointmentOrOccurrence: AppointmentOrOccurrence, toUpdate: Partial<AppointmentData>) => {
        try {
            if (appointmentOrOccurrence.occurrenceIndex !== undefined || appointmentOrOccurrence.occurrenceIndex === 0) {
                const recurrenceException = await Recurrence.createExceptionFromOnlineBooking(appointmentOrOccurrence as Occurrence, toUpdate, customerRecurrences);
                setSelectedAppointment(recurrenceException);
            } else {
                await Appointment.update(appointmentOrOccurrence.id, toUpdate);
                const updatedAppointment = { ...appointmentOrOccurrence, ...toUpdate };
                setSelectedAppointment(updatedAppointment);
            }
            await refreshAppointmentsAndRecurrences();
        }
        catch (error) {
            Sentry.captureException(error);
        }
    };

    const handleCodeVerificationNextButton = () => {
        if (backToCustomStep) {
            history.replace(`/online-booking/${backToCustomStep}`);
            setBackToCustomStep(null);
        }
        else {
            goToStep(steps.returning.welcome, undefined, true);
        }

        customer?.email && mixpanel.identify(customer.email);
    };

    useEffect(() => {
        if (!customStep) {
            setStepNumber(routeParams.stepNumber ? parseInt(routeParams.stepNumber) : steps.returning.initial);
            setSubstepNumber(routeParams.substepNumber ? parseInt(routeParams.substepNumber) : null);
        }
        else {
            const customStepNumbers = getNumbersForCustomStep(customStep);
            setStepNumber(customStepNumbers.step);
            setSubstepNumber(customStepNumbers.substep);
        }
    }, [routeParams.stepNumber, routeParams.substepNumber, customStep]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [stepNumber, substepNumber]);

    useEffect(() => {
        return () => {
            setPreviousStepNumber(stepNumber);
        };
    }, [stepNumber]);

    useEffect(() => {

        const prefetchCustomer = async () => {
            setIsPrefetchingCustomer(true);
            await fetchCustomerInternal();
            setIsPrefetchingCustomer(false);
        };

        const getDiscountCode = async (code: string) => {
            const discount = await DiscountCode.getByCode(code);
            discount && setDiscountCode(discount);
        };

        if (stepNumber >= steps.returning.welcome && location.search && !discountCode) {
            const params = new URLSearchParams(location.search);
            const discountCode = params.get("discountCode");
            if (discountCode)
                getDiscountCode(discountCode);
        }

        if (stepNumber >= steps.returning.welcome && !isSignedIn) {

            if (location.search) {
                const params = new URLSearchParams(location.search);
                const phoneNumber = params.get("phone");
                if (phoneNumber)
                    setShortcutPhone(phoneNumber);
            }

            if (customStep)
                setBackToCustomStep(customStep);

            goToStep(steps.returning.phoneInput, undefined, true);
            return;
        }

        if (stepNumber >= steps.returning.welcome && isSignedIn && !customer) {
            prefetchCustomer();
        }

        if (stepNumber > steps.returning.welcome && isSignedIn && !customer) {
            goToStep(steps.returning.welcome);
            return;
        }

        if (isBlockedUser &&
            (stepNumber > steps.returning.welcome || (stepNumber === steps.returning.welcome && substepNumber))) {
            goToStep(steps.returning.welcome);
        }

    }, [location.search, isSignedIn, onSignIn, stepNumber, goToStep, customer, substepNumber, isBlockedUser, customStep, discountCode, fetchCustomerInternal]);

    useEffect(() => {
        if (stepNumber === steps.returning.welcome && (previousStepNumber === steps.returning.thankYou) && isSignedIn && customer) {
            refreshAppointmentsAndRecurrences();
        }
    }, [stepNumber, previousStepNumber, isSignedIn, customer]);

    const trySignInWithEmailLink = async () => {
        const emailLink = window.location.href;
        const isSingInLink = isSignInWithEmailLink(authentication, emailLink);
        if (!isSingInLink)
            return;

        try {
            setIsLoading(true);
            const query = new URLSearchParams(search);
            const emailForSignIn = query.get("email");
            if (!emailForSignIn)
                throw new Error("Can't get email parameter. Please try again.");

            const userCredential = await signInWithEmailLink(authentication, emailForSignIn, emailLink);
            if (userCredential.user.isAnonymous) {
                const credential = EmailAuthProvider.credentialWithLink(emailForSignIn, emailLink);
                linkWithCredential(userCredential.user, credential);
            }
            handleCodeVerificationNextButton();
        }
        catch (error) {
            Sentry.captureException(error);
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSelectedAddressChange = (address: CustomerAddressData | SecondaryAddress) => {
        setSelectedAddress(address);
    };

    useEffect(() => {
        trySignInWithEmailLink();

        //HACK: We only want this useEffect to be triggered once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <Loading />
        );
    }

    const verificationPhoneNumber = phoneNumber || shortcutPhone;

    const handleOnHide = () => {
        if (stepNumber === steps.returning.manageAccount && substepNumber) {
            goToStep(steps.returning.manageAccount);
        }
        else if (stepNumber === steps.returning.thankYou && substepNumber) {
            goToStep(steps.returning.thankYou);
        }
        else {
            goToStep(steps.returning.welcome);
        }
    };

    return (
        <>
            <OnlineBookingHeader
                stepNumber={stepNumber}
                isLead={stepNumber === steps.returning.initial || stepNumber === steps.returning.phoneInput}
                substepNumber={substepNumber}
                isReturningOnlineBookingFlow={true}
                logoUrl={`/online-booking/${stepNumber < 3 ? "1" : steps.returning.welcome}`}
                onHide={handleOnHide} />

            {stepNumber === steps.returning.initial && <OnlineBookingStep1 onNext={() => goToStep(steps.returning.phoneInput)} />}
            {stepNumber === steps.returning.phoneInput &&
                <>
                    {substepNumber === steps.returning.phoneInputSubsteps.codeVerification && verificationPhoneNumber &&
                        <OnlineBookingCodeVerification
                            flowType={OnlineBookingFlowType.returningCustomer}
                            onNext={handleCodeVerificationNextButton}
                            phoneNumber={verificationPhoneNumber}
                        />}
                    {!substepNumber &&
                        <OnlineBookingStep2
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            onNext={authenticateCustomer}
                            isLoading={isLoadingCustomer}
                            showError={phoneNumberError || isBlockedUser}
                            shortcutPhone={shortcutPhone}
                        />}
                </>}
            {stepNumber === steps.returning.welcome && isPrefetchingCustomer && <Loading />}

            {customer && !isPrefetchingCustomer &&
                <OnlineBookingInnerContainer
                    stepNumber={stepNumber}
                    substepNumber={substepNumber}
                    customer={customer}
                    pets={pets}
                    setPets={setPets}
                    selectedServices={selectedServices}
                    setSelectedServices={setSelectedServices}
                    customerCredits={customerCredits}
                    customerUpcomingAppointments={customerUpcomingAppointments}
                    customerPastAppointments={customerPastAppointments}
                    onCustomerUpdate={setCustomer}
                    goToStep={goToStep}
                    isBlockedUser={isBlockedUser}
                    handleCancelAppointment={handleCancelAppointment}
                    setIsBlockedUser={setIsBlockedUser}
                    selectedAddress={selectedAddress}
                    addresses={customerAddresses}
                    setAddresses={setCustomerAddresses}
                    handleSelectedAddressChange={handleSelectedAddressChange}
                    discountCode={discountCode}
                    handleUpdateAppointment={handleUpdateAppointment}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                />}

            <div id="recaptcha-container"></div>
        </>
    );
}
