import { calculatePetAge, getDurationTextFromMinutes } from "@marathon/common/timeHelper";
import { PricingPresetBase } from "./PricingPreset";
import { ServiceBase } from "./Service";

interface PetData {
    name: string,
    //HACK: This redundant field is used to optimize the search by multiple customers
    customer_id: string,
    breed_id?: string,
    weight: number,
    notes: string,
    service_id?: string,
    price?: number,
    gender?: string,
    age_date?: Date,
    created_at: Date,
    status: PetStatus,
    is_deceased?: boolean,
    deceased_at?: Date,
    services: PetService[],
    profile_photo_url?: string
}

interface PetService {
    id: string,
    price: number
}

interface PetBase extends PetData {
    id: string,
    customerId: string
}

class PetBase {
    constructor(id: string, customerId: string, data: PetData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    getAge() {
        return (this.age_date
            ? calculatePetAge(this.age_date)
            : null
        );
    }
    isDefaultDog() {
        return this.breed_id === undefined;
    }
    isActive() {
        return this.status === PetStatus.active;
    }
    getDefaultServiceId() {
        return this.getDefaultService().id;
    }
    getDefaultService() {
        return this.services.sortByFieldDescending(x => x.price)[0];
    }
    getService(serviceId: string, pricingPreset: PricingPresetBase) {
        const petService = this.services.find(x => x.id === serviceId);
        if (petService) {
            return petService;
        }
        else {
            const servicePreset = pricingPreset.services.find(x => x.service_id === serviceId);
            if (!servicePreset)
                throw new Error("ServicePreset is required at this point");

            return ({
                id: serviceId,
                price: servicePreset.price
            });
        }
    }
    getServicesDescriptionForChatbotPrompt(services: ServiceBase[], pricingPreset: PricingPresetBase) {
        const getFormattedService = (id: string, label: string, estimatedTime: number, price?: number) => {
            return `{ serviceId: ${id}, serviceLabel: ${label}, serviceEstimatedTime: ${getDurationTextFromMinutes(estimatedTime)}, servicePrice: ${price ?? "Unknown"} }`;
        };

        const chatbotServices = services.filter(x => !x.tied_breed && x.offer_in_chatbot).map(service => {
            const serviceFromPreset = pricingPreset.services.find(x => x.service_id === service.id);
            return getFormattedService(service.id, service.name, service.estimated_time, serviceFromPreset?.price);
        });

        const petServices = this.services.map(x => {
            const service = services.find(s => s.id === x.id);
            if (!service)
                throw new Error(`Service ${x.id} not found`);

            return getFormattedService(x.id, service.description, service.estimated_time, x.price);
        });
        return [...petServices, ...chatbotServices];
    }
    toData(): PetData {
        const { id, customerId, ...data } = this;
        return data;
    }
    static getPetNames = (pets: PetBase[], emptyLabel = "your pet") => {
        const names = pets.map(x => x.name);
        const finalName = names.pop();
        if (!finalName) {
            return emptyLabel;
        }
        else {
            return (
                names.length
                    ? `${names.join(", ")} and ${finalName}`
                    : finalName
            );
        }
    };
}

enum PetStatus {
    active = "active",
    deleted = "deleted"
}

export { PetBase, PetStatus };
export type { PetData, PetService };
