import { Box } from "@mui/material";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { Customer } from "@marathon/client-side/entities/Customer";
import { getMobileServiceFeePrice } from "@marathon/common/mobileServiceFeeHelper";

interface Props {
    fee: MobileServiceFeeConfiguration,
    customer: Customer
}

export const MobileServiceFeePriceRow = ({ fee, customer }: Props) => {
    return (
        <Box className="pricing-row-container">
            <Box className="pricing-row-label">
                Mobile Service Fee
            </Box>
            <Box className="pricing-row-amount">{`$${getMobileServiceFeePrice(fee, customer)}`}</Box>
            <Box className="pricing-row-extra-description">
                The flat fee covers fuel & travel costs as well as daily  professional deep cleaning and sanitization.
            </Box>
        </Box>
    );
};