//
// DO NOT ADD NEW CODE TO THIS FILE
//
// We'll eventually refactor these out into their corresponding entity.
//

export const heicImageType = "image/heic";
export const jpegImageType = "image/jpeg";
export const gifImageType = "image/gif";

export const acceptedImageTypes = [
  gifImageType,
  heicImageType,
  jpegImageType,
  "image/jpg",
  "image/png",
  "image"
];

export const unsupportedVideoTypes = [
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt"
];

export const acceptedVideoTypes = [
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt",
  "video/h261",
  "video/h263",
  "video/h263-1998",
  "video/h263-2000",
  "video/h264",
  "video"
];

export const vcardFileTypes = [
  "text/vcard",
  "text/x-vcard"
];

export const customerTypes = {
  lead: "Lead",
  customer: "Customer",
  recurrent: "Recurrent"
};

export const mobileOperatingSystems = {
  android: "Android",
  iOS: "iOS"
};

export const activityOutcomeOptions = {
  answered_call_escalation: "Answered Call: Escalation",
  answered_call_rescheduled_appointment: "Answered Call: Rescheduled Appointment",
  answered_call_booked_appointment: "Answered Call: Booked Appointment",
  answered_call_did_not_book: "Answered Call: Did not book",
  answered_call_other: "Answered Call: Other",
  called_appointment_follow_up: "Called: Appointment Follow-Up",
  called_booked_appointment: "Called: Booked Appointment",
  called_booked_recurring: "Called: Booked Recurring",
  called_did_not_book: "Called: Did not book",
  called_other: "Called: Other",
  called_rescheduled_appointment: "Called: Rescheduled Appointment",
  called_unable_to_reach: "Called: Unable to reach, left VM",
  texted_booked_appointment: "Texted: Booked Appointment",
  texted_booked_recurring: "Texted: Booked Recurring",
  texted_no_reply: "Texted: No Reply",
  other_email_or_other_correspondence: "Other: Email or other correspondence",
  customer_churn_price: "Customer churn: Price",
  customer_churn_moved: "Customer churn: Moved",
  customer_churn_pet_passed: "Customer churn: Pet passed",
  customer_churn_service_issue: "Customer Churn: Service Issue",
  customer_churn_stylist_departure: "Customer Churn: Pet Stylist Departure",
  customer_churn_other: "Customer churn: Other"
};

export const MAX_IN_PARAMETERS_LENGTH = 30;
export const DEFAULT_MAXIMUM_DISTANCE = 25;
export const DEFAULT_MAXIMUM_DRIVE_TIME = 2400;
export const DEFAULT_TWILIO_PHONE = "8007429255";
export const UNDELIVERED_TWILIO_STATUS = "undelivered";
export const ONLINE_BOOKING_ORIGIN = "Online Booking";
export const SYSTEM_USER = "Marathon";
export const MARATHON_AI_USER = "Marathon AI";
export const MARATHON_AI_CHATBOT_USER = "Marathon Chatbot AI";
export const MARATHON_AI_VOICE_USER = "Marathon Voice AI";
export const CUSTOMER_USER = "Customer";
export const AIRCALL_ORIGIN = "Aircall";

export const leadTypes = {
  text_only: "Text Only",
  called_back: "Called Back",
  phone: "Phone",
  phone_auto: "Phone AUTO",
  yelp: "Yelp",
  outbound: "Outbound",
  text: "Text",
  web: "Web",
  live_call: "Live Call",
  other: "Other"
};

export const leadOutcomes = {
  closed_won: "Closed/Won",
  pending: "Pending",
  open_lead_created: "Open: Lead Created",
  open_lead_verification: "Open: Lead Verification",
  open_address_page: "Open: Address Page",
  open_dog_info_page: "Open: Dog Info Page",
  open_pre_pricing_page: "Open: Pre-Pricing Page",
  open_pricing_page: "Open: Pricing Page",
  open_find_appointment: "Open: Find Appointment",
  open_appointment_options: "Open: Appointment Options",
  open_confirmation: "Open: Confirmation",
  open_safety_page: "Open: Safety Page",
  left_vm_texted_first: "Left VM/Texted (First)",
  left_vm_texted_second: "Left VM/Texted (Second)",
  left_vm_texted_third: "Left VM/Texted (Third)",
  closed_lost_needed_immediate_appt: "Closed/Lost - Needed Immediate Appt",
  closed_lost_geography_limitation: "Closed/Lost - Geography Limitation",
  closed_lost_geography_limitation_waitlist: "Closed/Lost - Geography Limitation Waitlist",
  closed_lost_canceled: "Closed/Lost - Canceled",
  closed_lost_requested_service_not_offered: "Closed/Lost - Requested Service Not Offered",
  closed_lost_price: "Closed/Lost - Price",
  closed_lost_medical_vaccinations: "Closed/Lost - Medical/Vaccinations",
  closed_lost_informational_call: "Closed/Lost - Informational Call",
  closed_lost_response_time: "Closed/Lost - Response Time",
  closed_lost_no_response: "Closed/Lost - No Response",
  closed_lost_na: "Closed/Lost - N/A",
  new_lead_missed_call: "New lead: Missed call"
};

export const webWarmOutcomes = [
  leadOutcomes.open_lead_created,
  leadOutcomes.open_dog_info_page,
  leadOutcomes.open_address_page,
  leadOutcomes.open_pre_pricing_page
];

export const webHotOutcomes = [
  leadOutcomes.open_find_appointment,
  leadOutcomes.open_confirmation,
  leadOutcomes.open_safety_page,
  leadOutcomes.open_appointment_options
];

export const webColdOutcomes = [
  leadOutcomes.open_lead_verification,
  leadOutcomes.open_pricing_page
];

export const leadOrigins = [
  "Yelp",
  "Google AD",
  "Neighbor Referral",
  "Rescue Partner",
  "Local Outreach Event",
  "Facebook",
  "Instagram/Influencer",
  "TV/Press",
  "Van Marketing",
  "Mailer",
  ONLINE_BOOKING_ORIGIN
];

export const errorMessages = {
  refresh_and_try_again: "Something went wrong, please refresh the screen and try again.",
  try_again_or_call_us: "Something went wrong. If this keeps happening, please call or text us at (800) 742-9255.",
  address_required: "Address is required.",
  email_already_in_use: "Email is already in use.",
  phone_number_not_valid: "Phone number is not valid.",
};

export const soonestAppointmentFilters = Object.freeze({
  today: "today",
  todayOrYesterday: "today or yesterday",
  tomorrow: "tomorrow",
  hideTodayOrYesterday: "hide today or yesterday"
});

export const groomerScheduleTime = Object.freeze({
  minimum: "06:00",
  maximum: "22:00"
});

export const calendarTimeEdge = Object.freeze({
  startString: "06:00",
  startNumber: 6,
  endString: "23:00",
  endNumber: 23
});

export enum OnlineBookingFlowType {
  newCustomer = "new-customer",
  returningCustomer = "returning-customer"
}

export enum CustomerVerificationMode {
  phoneCode = "phoneCode",
  emailLink = "emailLink"
}

export enum TimeFilterOptionKey {
  morning = "morning",
  midday = "midday",
  afternoon = "afternoon"
}

export enum UserRoles {
  admin = "Admin",
  groomer = "Groomer"
}

export type DayOfWeek = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
export const zeroBasedDaysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] as DayOfWeek[];
export const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] as DayOfWeek[];

export const timeFilterOptions = {
  morning: { name: "Morning (8am - 11am)", min: 0, max: 11 },
  midday: { name: "Mid-Day (11am - 2pm)", min: 11, max: 14 },
  afternoon: { name: "Afternoon (2pm - 6pm)", min: 14, max: 24 }
} as const;

export const monthsRange = {
  under_3: { from: 0, to: 3, avr_in_years: 0.125, text: "Under 3 Months" },
  between_3_6: { from: 3, to: 6, avr_in_years: 0.375, text: "3-6 Months" },
  between_6_12: { from: 6, to: 12, avr_in_years: 0.75, text: "6 Months - 1 year" },
} as const;

export const monthsRangeList = [
  monthsRange.under_3,
  monthsRange.between_3_6,
  monthsRange.between_6_12
] as const;

export const MessageTemplateReplaceToken = {
  customerFirstName: "[%customerFirstName%]",
  customerDogNames: "[%customerDogNames%]",
  nextAppointmentDate: "[%nextAppointmentDate%]",
  nextAppointmentDogNames: "[%nextAppointmentDogNames%]",
  nextAppointmentPrice: "[%nextAppointmentPrice%]",
  safetyUrl: "[%safetyUrl%]"
} as const;

export const cancellationReasons = [
  {
    text: "Pet Stylist Rebooked another appointment",
    whenUse: "The Pet Stylist worked with the Pet Parent to reschedule a different time/date"
  },
  {
    text: "Client Requested Cancellation - Price",
    whenUse: "Client has issues with new/current rates"
  },
  {
    text: "Client Requested Cancellation - Will Reschedule",
    whenUse: "Client had a schedule conflict and wants to rebook"
  },
  {
    text: "Client Requested Cancellation - Service Issues",
    whenUse: "Client has requested a cancellation due to a service issue in the past or currently happening"
  },
  {
    text: "Client Requested Cancellation - No Response",
    whenUse: "Client is not responding to communications"
  },
  {
    text: "Client No-Show",
    whenUse: "Client confirmed an appointment.  However, upon arrival did not answer door, turned away Pet Stylist, or stopped responding all together"
  },
  {
    text: "CS Booking Error - Pricing",
    whenUse: "An error was made on the part of the CS and Sales Team"
  },
  {
    text: "CS Booking Error - Medical/Vaccinations",
    whenUse: "A booking was made when a dog does not have all vaccines or has medical issues (i.e. an open wound)"
  },
  {
    text: "CS Booking Error - Scheduling",
    whenUse: "An error was made on the scheduling for an appointment from the CS and Sales Team"
  },
  {
    text: "Pet Stylist Call Out - Appointment rescheduled",
    whenUse: "The Pet Stylist called out sick and the Pet Parent was alright with rescheduling"
  },
  {
    text: "Pet Stylist Call Out - Appointment not rescheduled",
    whenUse: "The Pet Stylist called out sick and the Pet Parent was not open to rescheduling"
  },
  {
    text: "Service Refusal",
    whenUse: "The pet was not groomable. Tried Ears, Nails, Teeth and it was not safe for the groom"
  },
  {
    text: "Pet Stylist Departure - Appointment rescheduled",
    whenUse: "The Pet Stylist is no longer working with Barkbus or in this Hub - Pet Parent rescheduled appointment"
  },
  {
    text: "Pet Stylist Departure - Appointment not rescheduled",
    whenUse: "The Pet Stylist is no longer working with Barkbus or in this Hub - Pet Parent did not want to reschedule"
  },
  {
    text: "Dog passed away",
    whenUse: "The Pet Parent has informed us that their dog has passed away"
  },
  {
    text: "Customer Moved out of Service Area",
    whenUse: "The Pet Parent has moved out of the service area"
  }
] as const;

export const mixPanelEvent = {
  ob_onlineBookingStart: "OB - Online Booking Start",
  ob_new_getStarted: "OB - New - Get Started",
  ob_new_phoneConfirm: "OB - New - Phone Confirm",
  ob_new_accountExist: "OB - New - Account Exist",
  ob_new_location: "OB - New - Location",
  ob_new_dogInfo: "OB - New - Dog Info",
  ob_new_exceedServiceArea: "OB - New - Exceed Service Area",
  ob_new_exceedServiceArea_waitList: "OB - New - Exceed Service Area Wait List",
  ob_new_prePricing: "OB - New - Pre Pricing",
  ob_new_pricing: "OB - New - Pricing",
  ob_new_dates: "OB - New - Dates",
  ob_new_confirm: "OB - New - Confirm",
  ob_new_safety: "OB - New - Safety",
  ob_new_finalStep: "OB - New - Final Step",
  ob_new_finalStep_addToCal: "OB - New - Final Step - Add to Cal",
  ob_new_finalStep_blogClick: "OB - New - Final Step - Blog Click",
  ob_returningCustomer: "OB - Returning Customer",
  ob_return_phoneConfirm: "OB - Return - Phone Confirm",
  ob_return_welcomeBack: "OB - Return - Welcome Back",
  ob_return_pricing: "OB - Return - Pricing",
  ob_return_pricing_addDog: "OB - Return - Pricing - Add Dog",
  ob_return_dates: "OB - Return - Dates",
  ob_return_confirm: "OB - Return - Confirm",
  ob_return_safety: "OB - Return - Safety",
  ob_return_finalStep: "OB - Return - Final Step",
  ob_return_finalStep_addToCal: "OB - Return - Final Step - Add to Cal",
  ob_return_finalStep_blogClick: "OB - Return - Final Step - Blog Click",
  ob_return_klaviyoBookingSuggestion_confirm: "OB - Return - Booking Suggestion - Confirm",
} as const;