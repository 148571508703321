import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { Hub } from "@marathon/client-side/entities/Hub";
import CreatePaymentMethod from "./CreatePaymentMethod";
import { Customer } from "@marathon/client-side/entities/Customer";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import Swal from "sweetalert2";
import { OnlineBookingFlowType } from "@marathon/common/constants";
import { CreditCard } from "@marathon/client-side/entities/CreditCard";

interface ThankYouAddCardProps {
    customer: Customer,
    hubs: Hub[],
    flowType: OnlineBookingFlowType,
    handlePaymentMethodAddedSuccess: () => void
    cards: CreditCard[],
    setCards: (cards: CreditCard[]) => void
}

export default function ThankYouAddCard({ customer, hubs, flowType, cards, setCards, handlePaymentMethodAddedSuccess }: ThankYouAddCardProps) {

    const validateSquareupId = async () => {
        const retryInterval = 3000;
        const maxRetries = 2;

        if (flowType === OnlineBookingFlowType.returningCustomer) {
            return !!customer.squareup_id;
        } else {
            for (let attempt = 0; attempt < maxRetries; attempt++) {
                const freshCustomerResponse = await CallableFunctions.public.getCurrentCustomer(customer.phone);
                if (freshCustomerResponse?.customer.squareup_id)
                    return true;

                await new Promise(resolve => setTimeout(resolve, retryInterval));
            }
            return false;
        }
    };

    const handleCardTokenized = async (token: string) => {
        const customerHasSquareupId = await validateSquareupId();
        if (!customerHasSquareupId) {
            Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again later." });
            return;
        }

        const newCard = await CallableFunctions.square.createCustomerSquareCard(token);
        if (newCard) {
            setCards([...cards, newCard]);
            handlePaymentMethodAddedSuccess();
        } else {
            Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again later." });
        }
    };

    return (
        <OnlineBookingContentWrapper>
            <CreatePaymentMethod
                customer={customer}
                onCardTokenized={handleCardTokenized}
                hubs={hubs}
            />
        </OnlineBookingContentWrapper>
    );
}
