import { TimeZone } from "@marathon/common/timeZoneHelper";
import { getDistanceInMiles } from "../mapHelper";

enum HubGroup {
    AllPacific = "all-pacific-hubs",
    AllCentral = "all-central-hubs",
    AllArizona = "all-arizona-hubs",
    AllEastern = "all-eastern-hubs",
    AllHubs = "all-hubs"
}

const HubGroups = {
    AllPacific: { id: HubGroup.AllPacific, timeZone: TimeZone.PacificTime },
    AllCentral: { id: HubGroup.AllCentral, timeZone: TimeZone.CentralTime },
    AllArizona: { id: HubGroup.AllArizona, timeZone: TimeZone.ArizonaTime },
    AllEastern: { id: HubGroup.AllEastern, timeZone: TimeZone.EasternTime },
    AllHubs: { id: HubGroup.AllHubs, timeZone: TimeZone.PacificTime }
} as const;

interface HubData {
    acronym: string,
    full_address: string,
    name: string,
    phone_number: string,
    place_id: string,
    vertices: Vertex[],
    coordinates: Vertex,
    pricing_preset_id: string
    time_zone: TimeZone,
    maximum_distance?: number,
    maximum_drive_time?: number,
    prices_include_tax?: true,
    first_time_customer_discount?: {
        value: number,
        is_enabled: boolean
    }
    goal: {
        value: number,
        thismn: number
    },
    google_feedback_url: string,
    square_location_id: string
}

interface Vertex {
    lat: number,
    lng: number
}

interface HubBase extends HubData {
    id: string
}

class HubBase {
    constructor(id: string, data: HubData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): HubData {
        const { id, ...data } = this;
        return data;
    }
    getFormattedPhoneNumber() {
        const match = this.phone_number.match(/(\d{3})(\d{3})(\d{4})/);
        if (!match) {
            return this.phone_number;
        }
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    static defaultSalesGoal() {
        return {
            value: 0,
            thismn: 1
        };
    }
    npsFeedbackUrl() {
        return `https://www.barkbus.com/feedback/${this.acronym.toLowerCase()}`;
    }
    getDistanceFromHubInMiles(destinationCoordinates: Vertex) {
        return getDistanceInMiles(this.coordinates, destinationCoordinates);
    }
}

export { HubBase, HubGroup, HubGroups };
export type { HubData };