import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { Groomer } from "@marathon/client-side/entities/Groomer";
import { User } from "@marathon/client-side/entities/User";
import { UserCredential } from "firebase/auth";
import { AppointmentSeed } from "@marathon/client-side/entities/AppointmentSeed";

const UID_KEY = "uidfir";
const USERNAME_KEY = "username";
const USERROLE_KEY = "userrole";

export function dateTimeReviver(_: unknown, value: unknown) {
    let a;
    if (typeof value === "string") {
        a = Date.parse(value);
        if (a) {
            return new Date(+a);
        }
    }
    return value;
}

class LocalStorage {
    public static setCurrentUser(firebaseResult: UserCredential, currentUser: Groomer | User) {
        const uid = firebaseResult.user.uid;
        const name = `${currentUser.firstname} ${currentUser.lastname}`;
        const role = currentUser.role;

        localStorage.setItem(UID_KEY, uid);
        localStorage.setItem(USERNAME_KEY, name);
        localStorage.setItem(USERROLE_KEY, role);

        DeviceStorageCache.setCurrentUser(uid, name, role);
    }
    public static clearCurrentUser() {
        localStorage.removeItem(UID_KEY);
        localStorage.removeItem(USERNAME_KEY);
        localStorage.removeItem(USERROLE_KEY);

        DeviceStorageCache.setCurrentUser(null, null, null);
    }
    public static hydrateCache() {
        const uid = localStorage.getItem(UID_KEY);
        const name = localStorage.getItem(USERNAME_KEY);
        const role = localStorage.getItem(USERROLE_KEY);
        DeviceStorageCache.setCurrentUser(uid, name, role);
    }
    public static isUserAuthenticated() {
        return localStorage.getItem(UID_KEY) !== null;
    }
    public static setStoredAppointmentSeed(appointmentSeed: AppointmentSeed) {
        localStorage.setItem("appointmentSeed", JSON.stringify(appointmentSeed));
    }
    public static getStoredAppointmentSeed() {
        const localData = localStorage.getItem("appointmentSeed");
        return (
            localData
                ? JSON.parse(localData, dateTimeReviver) as AppointmentSeed
                : null
        );
    }
    public static clearStoredAppointmentSeed() {
        localStorage.removeItem("appointmentSeed");
    }
}

export default LocalStorage;