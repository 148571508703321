import { HttpsCallableOptions, getFunctions, httpsCallable } from "firebase/functions";
import { Appointment } from "@marathon/client-side/entities/Appointment";
import { Customer } from "@marathon/client-side/entities/Customer";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Credit } from "@marathon/client-side/entities/Credit";
import { Recurrence } from "@marathon/client-side/entities/Recurrence";
import { BookingSuggestion, BookingSuggestionSearchParameters } from "@marathon/common/api/BookingSuggestion";
import { HuntedSuggestion, HuntedSuggestionSearchParameters } from "@marathon/common/api/HuntedSuggestion";
import { GroomerCalendarGap, GroomerCalendarGapSearchParameters } from "@marathon/common/api/GroomerCalendarGap";
import { CalendarOverlap, CalendarOverlapSearchParameters } from "@marathon/common/api/CalendarOverlap";
import { AppointmentsReportFilterBy, AppointmentsReportParameters } from "@marathon/common/api/AppointmentsReport";
import { CustomersReportParameters } from "@marathon/common/api/CustomersReport";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { CustomerPublicInfoParameters, CustomerPublicInfoResponse, CustomerEmailParameters, CustomerEmailResponse } from "@marathon/common/api/CustomerInfo";
import { InvitationCodeInfoParameters, InvitationCodeInfoResponse } from "@marathon/common/api/InvitationCodeInfo";
import { RegisterUserParameters } from "@marathon/common/api/User";
import { EnableChatbotParameters } from "@marathon/common/api/EnableChatbot";
import { BroadcastMessageParameters } from "@marathon/common/api/BroadcastMessage";
import { IsNewCustomerParameters } from "@marathon/common/api/IsNewCustomer";
import { IsFirstAppointmentParameters } from "@marathon/common/api/IsFirstAppointment";
import { CreateSquareCardRequest, DisableCreditCardResponse, DisableSquareCardRequest, GetSquareCardsRequest, CreateSquareProfileRequest } from "@marathon/common/api/Square";
import { TimeZone } from "@marathon/common/timeZoneHelper";
import LocalDate from "@marathon/common/LocalDate";
import { QueryFilter } from "@marathon/common/api/QueryFilter";
import { Gender } from "@marathon/common/entities/Groomer";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { SecondaryAddress } from "@marathon/client-side/entities/SecondaryAddress";
import { FunctionParamsBase } from "@marathon/common/api/FunctionParamsBase";
import { CreditCard } from "@marathon/client-side/entities/CreditCard";
import { RouteMatchingDrivetimeParameters, RouteMatchingDrivetimeResponse } from "@marathon/common/api/RouteMatchingDrivetime";
import { DiscountCode } from "@marathon/client-side/entities/DiscountCode";
import { datesToTimestamps, timestampsToDates } from "../helpers/serializationHelper";

const functions = getFunctions();

export default class CallableFunctions {
    static get artificialIntelligence() {
        const prefix = "artificialIntelligence";
        return {
            enableChatbot: async (customerId: string, initialMessageCount: number, turnedOnBy: string, context?: string) => {
                const functionName = `${prefix}-enableChatbot`;
                await callFunction<EnableChatbotParameters, void>(functionName, { customerId, initialMessageCount, turnedOnBy, context });
            },
            syncAssistant: async (promptId: string) => {
                const functionName = `${prefix}-syncAssistant`;
                await callFunction<SyncAssistantParameters, void>(functionName, { promptId });
            }
        };
    }
    static get broadcastMessage() {
        const prefix = "broadcastMessage";
        return {
            sendBroadcastMessage: async (customerIds: string[], content: string) => {
                const functionName = `${prefix}-sendBroadcastMessage`;
                await callFunction<BroadcastMessageParameters, void>(functionName, { customerIds, content });
            }
        };
    }
    static get insights() {
        const prefix = "insights";
        return {
            getHuntedSuggestions: async (
                startDate: LocalDate,
                endDate: LocalDate,
                lapsedForDays: number,
                maximumLapsedDays: number,
                maximumDistanceMiles: number,
                hubId: string,
                noActivityForDays: number,
                targetAppointmentId?: string
            ) => {
                const functionName = `${prefix}-getHuntedSuggestions`;
                const parameters = { startDate, endDate, lapsedForDays, maximumLapsedDays, maximumDistanceMiles, hubId, noActivityForDays, targetAppointmentId } as HuntedSuggestionSearchParameters;
                const data = await callFunction(functionName, parameters, { timeout: 300_000 });
                return data as HuntedSuggestion[];
            },
            getGroomerCalendarGaps: async (
                startDate: LocalDate,
                endDate: LocalDate,
                hubOptionId: string) => {
                const functionName = `${prefix}-getGroomerCalendarGaps`;
                const parameters = { startDate, endDate, hubOptionId } as GroomerCalendarGapSearchParameters;
                const data = await callFunction(functionName, parameters);
                return data as GroomerCalendarGap[];
            },
            getCalendarOverlaps: async (
                startDate: LocalDate,
                endDate: LocalDate,
                hubOptionId: string) => {
                const functionName = `${prefix}-getCalendarOverlaps`;
                const parameters = { startDate, endDate, hubOptionId } as CalendarOverlapSearchParameters;
                const data = await callFunction(functionName, parameters);
                return data as CalendarOverlap[];
            },
            refreshAllRegionsTotals: async () => {
                const functionName = `${prefix}-refreshAllRegionsTotals`;
                return await callFunction(functionName, {});
            }
        };
    }
    static get public() {
        const prefix = "public";
        return {
            getCustomerPublicInfo: async (params: CustomerPublicInfoParameters): Promise<CustomerPublicInfoResponse | null> => {
                const functionName = `${prefix}-getCustomerPublicInfo`;
                return await callFunction<CustomerPublicInfoParameters, CustomerPublicInfoResponse | null>(functionName, params);
            },
            getCustomerEmail: async (params: CustomerEmailParameters): Promise<CustomerEmailResponse | null> => {
                const functionName = `${prefix}-getCustomerEmail`;
                return await callFunction<CustomerEmailParameters, CustomerEmailResponse | null>(functionName, params);
            },
            getInvitationCodeInfo: async (params: InvitationCodeInfoParameters): Promise<InvitationCodeInfoResponse | null> => {
                const functionName = `${prefix}-getInvitationCodeInfo`;
                return await callFunction<InvitationCodeInfoParameters, InvitationCodeInfoResponse | null>(functionName, params);
            },
            getCurrentCustomer: async (phone?: string, onlyNonDefault = true): Promise<GetCustomerResponse | null> => {
                const functionName = `${prefix}-getCurrentCustomer`;
                const data = await callFunction<GetCustomerRequest, GetCustomerResponseRawData | null>(functionName, { phone, onlyNonDefault });
                if (!data) {
                    return null;
                }
                else {
                    return {
                        customer: Customer.fromApi(data.customer),
                        pets: data.pets.map(x => Pet.fromApi(x)),
                        credits: data.credits.map(x => Credit.fromApi(x)),
                        secondaryAddresses: data.secondaryAddresses.map(x => SecondaryAddress.fromApi(x))
                    };
                }
            },
            getRouteMatchingDriveTimes: async (params: RouteMatchingDrivetimeParameters): Promise<RouteMatchingDrivetimeResponse | null> => {
                const functionName = `${prefix}-getRouteMatchingDriveTimes`;
                return await callFunction<RouteMatchingDrivetimeParameters, RouteMatchingDrivetimeResponse | null>(functionName, params);
            },
            getBookingSuggestions: async (
                estimatedTime: number,
                customerId: string,
                serviceIds: string[],
                selectedAddress: CustomerAddressData | SecondaryAddress,
                specificDate?: LocalDate,
                groomerId?: string,
                timeRange?: { min: number, max: number },
                rankingCriteria?: string,
                ignoreDistanceLimit?: true,
                daysOfWeek?: string[],
                groomerGender?: Gender
            ) => {
                const functionName = `${prefix}-getBookingSuggestions`;
                const parameters = { estimatedTime, customerId, serviceIds, selectedAddress, specificDate, groomerId, timeRange, rankingCriteria, ignoreDistanceLimit, daysOfWeek, groomerGender } as BookingSuggestionSearchParameters;
                const data = await callFunction(functionName, parameters);
                return data as BookingSuggestion[];
            },
            getAppointmentOnHoldData: async (appointmentId: string): Promise<AppointmentOnHoldDataResponse | null> => {
                const functionName = `${prefix}-getAppointmentOnHoldData`;
                const data = await callFunction<AppointmentOnHoldRequest, AppointmentOnHoldDataResponseRawData | null>(functionName, { appointmentId });
                if (!data) {
                    return null;
                }
                else {
                    return {
                        appointment: Appointment.fromApi(data.appointment),
                        customerPets: data.customerPets.map(x => Pet.fromApi(x))
                    };
                }
            },
            getBookingSuggestionData: async (token: string, discountCode?: string): Promise<BookingSuggestionDataResponse | null> => {
                const functionName = `${prefix}-getBookingSuggestionData`;
                const data = await callFunction<BookingSuggestionRequest, BookingSuggestionResponseRawData | null>(functionName, { token, discountCode });
                if (!data) {
                    return null;
                }
                else {
                    return {
                        bookingSuggestion: data.suggestion as BookingSuggestion,
                        customer: Customer.fromApi(data.customer),
                        selectedServices: data.selectedServices as { petId: string, serviceId: string }[],
                        pets: data.pets.map(x => Pet.fromApi(x)),
                        credits: data.credits.map(x => Credit.fromApi(x)),
                        isValid: data.isValid,
                        discount: data.discount ? DiscountCode.fromApi(data.discount) : undefined
                    };
                }
            },
            getCustomerAppointments: async (filters: QueryFilter[] = [], numberOfOccurrences?: number) => {
                const functionName = `${prefix}-getCustomerAppointments`;
                const parameters = { filters, numberOfOccurrences } as CustomerAppointmentParameters;
                const data = await callFunction(functionName, parameters);
                return (data ?? []) as AppointmentOrOccurrence[];
            },
            getCustomerRecurrences: async () => {
                const functionName = `${prefix}-getCustomerRecurrences`;
                const data = await callFunction<FunctionParamsBase, unknown[]>(functionName, {});
                return data.map(x => Recurrence.fromApi(x));
            },
            isNewCustomer: async (customerId: string) => {
                const functionName = `${prefix}-isNewCustomer`;
                return await callFunction<IsNewCustomerParameters, boolean>(functionName, { customerId });
            },
            isFirstAppointment: async (groomerId: string, startTime: Date, timeZone: TimeZone) => {
                const functionName = `${prefix}-isFirstAppointment`;
                const parameters = { groomerId, startTime, timeZone } as IsFirstAppointmentParameters;
                return await callFunction<IsFirstAppointmentParameters, boolean>(functionName, parameters);
            }
        };
    }
    static get reports() {
        const prefix = "reports";
        return {
            getAppointmentsReport: async (startDate: LocalDate, endDate: LocalDate, timeZone: TimeZone, filterBy: AppointmentsReportFilterBy) => {
                const functionName = `${prefix}-appointments`;
                const parameters = { startDate, endDate, timeZone, timeOffsetInMinutes: new Date().getTimezoneOffset(), filterBy } as AppointmentsReportParameters;
                const data = await callFunction(functionName, parameters, { timeout: 540_000 });
                return data as unknown[];
            },
            getCustomersReport: async (hasUpcoming?: boolean, startAgo?: number, endAgo?: number, createdInYear?: number) => {
                const functionName = `${prefix}-customers`;
                const parameters = { hasUpcoming, startAgo, endAgo, createdInYear, timeOffsetInMinutes: new Date().getTimezoneOffset() } as CustomersReportParameters;
                const data = await callFunction(functionName, parameters, { timeout: 300_000 });
                return data as unknown[];
            },
            getRecurrencesReport: async () => {
                const functionName = `${prefix}-recurrences`;
                const data = await callFunction(functionName, {}, { timeout: 300_000 });
                return data as unknown[];
            }
        };
    }
    static get square() {
        const prefix = "square";
        return {
            getCustomerSquareCards: async (customerId?: string): Promise<CreditCard[]> => {
                const functionName = `${prefix}-getCustomerSquareCards`;
                const data = await callFunction<GetSquareCardsRequest, unknown[]>(functionName, { customerId });
                return data.map(x => CreditCard.fromApi(x));
            },
            createCustomerSquareProfile: async (customerId: string): Promise<void> => {
                const functionName = `${prefix}-createCustomerSquareProfile`;
                return await callFunction<CreateSquareProfileRequest, void>(functionName, { customerId });
            },
            createCustomerSquareCard: async (cardToken: string, customerId?: string): Promise<CreditCard | null> => {
                const functionName = `${prefix}-createCustomerSquareCard`;
                const data = await callFunction<CreateSquareCardRequest, unknown>(functionName, { cardToken, customerId });
                return data ? CreditCard.fromApi(data) : null;
            },
            disableCustomerSquareCard: async (creditCardId: string, customerId?: string): Promise<DisableCreditCardResponse> => {
                const functionName = `${prefix}-disableCustomerSquareCard`;
                return await callFunction<DisableSquareCardRequest, DisableCreditCardResponse>(functionName, { creditCardId, customerId });
            }
        };
    }
    static get twilio() {
        const prefix = "twilio";
        return {
            getTwilioToken: async () => {
                const functionName = `${prefix}-getToken`;
                return await callFunction<FunctionParamsBase, GetTwilioTokenResponse>(functionName, {});
            }
        };
    }
    static get users() {
        const prefix = "users";
        return {
            registerUser: async (params: RegisterUserParameters) => {
                const functionName = `${prefix}-register`;
                return await callFunction<RegisterUserParameters, RegisterUserResponse>(functionName, params);
            },
            deleteUser: async (uid: string) => {
                const functionName = `${prefix}-delete`;
                return await callFunction<DeleteUserRequest, GenericUserOperationResponse>(functionName, { uid });
            },
            updateUserPassword: async (uid: string, password: string) => {
                const functionName = `${prefix}-updatePassword`;
                return await callFunction<UpdatePasswordRequest, GenericUserOperationResponse>(functionName, { uid, password });
            }
        };
    }
}

async function callFunction<T extends FunctionParamsBase, U>(name: string, parameters: T, options?: HttpsCallableOptions): Promise<U> {
    const callableFunction = httpsCallable<T, U>(functions, name, options);
    setClientAppVersion(parameters);
    datesToTimestamps(parameters);
    const { data } = await callableFunction(parameters);
    timestampsToDates(data);
    return data;
}

function setClientAppVersion<T extends FunctionParamsBase>(parameters: T) {
    if (!process.env.REACT_APP_CLIENT_VERSION)
        return;

    parameters.clientAppVersion = process.env.REACT_APP_CLIENT_VERSION;
}

interface GetCustomerRequest extends FunctionParamsBase {
    phone?: string,
    onlyNonDefault: boolean
}

interface CustomerAppointmentParameters extends FunctionParamsBase {
    filters: QueryFilter[],
    numberOfOccurrences?: number
}

interface AppointmentOnHoldRequest extends FunctionParamsBase {
    appointmentId: string
}

interface BookingSuggestionRequest extends FunctionParamsBase {
    token: string,
    discountCode?: string
}

interface DeleteUserRequest extends FunctionParamsBase {
    uid: string
}

interface UpdatePasswordRequest extends FunctionParamsBase {
    uid: string,
    password: string
}

interface SyncAssistantParameters extends FunctionParamsBase {
    promptId: string
}

interface GetCustomerResponseRawData {
    customer: unknown,
    pets: unknown[],
    credits: unknown[],
    secondaryAddresses: unknown[],
    cards: CreditCard[]
}

interface GetCustomerResponse {
    customer: Customer,
    pets: Pet[],
    credits: Credit[],
    secondaryAddresses: SecondaryAddress[]
}

interface AppointmentOnHoldDataResponseRawData {
    appointment: unknown,
    customerPets: unknown[]
}

interface BookingSuggestionResponseRawData {
    suggestion: unknown,
    customer: unknown,
    selectedServices: unknown[],
    pets: unknown[],
    credits: unknown[],
    isValid: boolean,
    discount: unknown,
    isDiscountValid: unknown
}

interface AppointmentOnHoldDataResponse {
    appointment: Appointment,
    customerPets: Pet[]
}

interface BookingSuggestionDataResponse {
    bookingSuggestion: BookingSuggestion,
    customer: Customer,
    selectedServices: { petId: string, serviceId: string }[],
    pets: Pet[],
    credits: Credit[],
    isValid: boolean,
    discount?: DiscountCode
}

interface GetTwilioTokenResponse {
    token: string
}

interface GenericUserOperationResponse {
    status: "success" | "error",
    message: string
}

interface RegisterUserResponse {
    status: "success" | "error",
    uid?: string,
    message?: string
}