import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import { Box } from "@mui/material";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { CreditCard } from "@marathon/client-side/entities/CreditCard";
import { Button } from "@marathon/web/components/Button";
import { CreditCardStatus } from "@marathon/common/entities/CreditCard";
import { CreditCardDetails } from "@marathon/web/components/paymentMethods/CreditCardDetails";

interface CardInfoProps {
    card: CreditCard,
    isDisablingCard: boolean,
    onDisableCardClick: (creditCardId: string) => void,
}

const CardInfo = ({ card, isDisablingCard, onDisableCardClick }: CardInfoProps) => {
    const classes = useStyles();

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Card Details" />
            <ContentWrapper style={{ fontFamily: "Plain Bold" }}>
                <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px" }}>
                    <h1 style={{ fontSize: "25px", margin: 0 }}>{card.card_brand}</h1>
                    <Box sx={{ mt: 2 }}>
                        <CreditCardDetails creditCard={card} />
                    </Box>
                </Box>
                {card.status === CreditCardStatus.Active && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        showSpinner={isDisablingCard}
                        className={classes.buttonSecondary}
                        onClick={() => onDisableCardClick(card.id)}
                        disableElevation>
                        Disable Card
                    </Button>
                )}
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default CardInfo;