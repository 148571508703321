
import { PetInput } from "@marathon/client-side/entities/PetInput";
import { Breed } from "@marathon/client-side/entities/Breed";
import PetFormWrapper from "../PetFormWrapper";
import { Pet } from "@marathon/client-side/entities/Pet";

interface Props {
  pets: Pet[],
  editingPetIndex: number,
  handlePetChange: (index: number, newValue: PetInput) => void,
  onNext: (index: number) => void,
  breeds: Breed[],
  formError: string,
  isLoading: boolean
}

export default function LeadStepPetDetails({
  pets,
  editingPetIndex,
  handlePetChange,
  onNext,
  breeds,
  formError,
  isLoading
}: Props) {

  const handleNext = () => {
    onNext(editingPetIndex);
  };

  if (!pets.length)
    return null;

  return (
    <PetFormWrapper
      pet={pets[editingPetIndex].toInput()}
      handlePetChange={newValue => handlePetChange(editingPetIndex, newValue)}
      onNext={handleNext}
      lastPetIndex={editingPetIndex}
      breeds={breeds}
      formError={formError}
      isLoading={isLoading}
    />
  );
}
