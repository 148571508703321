import { customerCreditsCollection, getNonEmptyData, getParentId } from "@marathon/client-side/database";
import { DocumentSnapshot, getDocs, doc, query, where, deleteDoc, addDoc, updateDoc } from "firebase/firestore";
import { CreditBase, CreditData } from "@marathon/common/entities/Credit";

const mapEntity = function (snapshot: DocumentSnapshot<CreditData>) {
    return new Credit(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class Credit extends CreditBase {
    static async create(customerId: string, credit: CreditData) {

        const collection = customerCreditsCollection(customerId);
        const newDocument = await addDoc(collection, credit);
        return new Credit(newDocument.id, customerId, credit);
    }
    static async search(customerId: string) {
        const snapshot = await getDocs(
            query(customerCreditsCollection(customerId)));
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async update(id: string, credit: CreditData, customerId: string) {
        const collection = customerCreditsCollection(customerId);
        const reference = doc(collection, id);
        await updateDoc(reference, { ...credit });
    }
    static async remove(customerId: string, creditId: string) {
        const collection = customerCreditsCollection(customerId);
        const reference = doc(collection, creditId);
        await deleteDoc(reference);
    }
    static async removeByReferredId(customerId: string, customerReferredId: string) {
        const collection = customerCreditsCollection(customerId);
        const customQuery = query(collection,
            where("customer_referred_id", "==", customerReferredId),
        );
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return;
        }
        const reference = doc(collection, mapEntity(snapshot.docs[0]).id);
        deleteDoc(reference);
    }
    static async getByCustomerReferredId(customerId: string, customerReferredId: string) {
        const collection = customerCreditsCollection(customerId);

        const customQuery = query(collection,
            where("customer_referred_id", "==", customerReferredId),
        );
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return null;
        }
        return mapEntity(snapshot.docs[0]);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new Credit(id, customerId, data);
    }
}