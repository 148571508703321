import { Fragment } from "react";
import { Button } from "@marathon/web/components/Button";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Service } from "@marathon/client-side/entities/Service";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { useOnlineBookingContext } from "../OnlineBookingContext";
import { PetServiceSelection } from "@marathon/web/components/onlineBooking/PetServiceSelection";
import { MobileServiceFeePriceRow } from "../MobileServiceFeePriceRow";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { DiscountPriceRow } from "../DiscountPriceRow";
import { Discount } from "@marathon/client-side/entities/Discount";
import { DiscountType } from "@marathon/common/entities/Discount";
import LineDivider from "../shared/LineDivider";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import { Customer } from "@marathon/client-side/entities/Customer";
import { Hub } from "@marathon/client-side/entities/Hub";
import { DiscountCardFirstOccurrence } from "../shared/DiscountCardFirstOccurrence";
import { DiscountCardFirstTimeCustomer } from "../shared/DiscountCardFirstTimeCustomer";

interface Props {
    onNext: () => Promise<void>,
    pets: Pet[]
    services: Service[],
    handleChangeSelectedService: (petId: string, serviceId: string) => void,
    selectedServices: { petId: string, serviceId: string }[],
    hasMobileServiceFee: boolean,
    mobileServiceFee: MobileServiceFeeConfiguration,
    invitationCode: string | null,
    customer: Customer,
    discounts: Discount[],
    hubs: Hub[]
}

export default function LeadStepPricing({
    onNext,
    pets,
    services,
    selectedServices,
    handleChangeSelectedService,
    hasMobileServiceFee,
    mobileServiceFee,
    invitationCode,
    customer,
    discounts,
    hubs
}: Props) {

    const classes = useStyles();
    const { breeds } = useOnlineBookingContext();

    const referralDiscount = discounts.find(x => x.type === DiscountType.referral);
    const showFirstOccurrenceDiscount = customer.qualifiesFor.firstOccurrenceDiscount(hubs, invitationCode || undefined);
    const showFirstTimeCustomerDiscount = customer.qualifiesFor.firstTimeCustomerDiscount(hubs, invitationCode || undefined);

    return (
        <OnlineBookingContentWrapper className="online-booking-step-container">
            <TitleWrapper title="Pricing" />
            <ContentWrapper>
                <LineDivider marginBottom={20} />
                {showFirstOccurrenceDiscount && <DiscountCardFirstOccurrence discounts={discounts} />}
                {showFirstTimeCustomerDiscount && <DiscountCardFirstTimeCustomer hubs={hubs} customer={customer} />}
                {pets.map((pet, idx) =>
                    <Fragment key={idx}>
                        <PetServiceSelection
                            key={idx}
                            checked
                            handleChangeSelectedService={handleChangeSelectedService}
                            pet={pet}
                            services={services}
                            breeds={breeds}
                            selectedServiceId={selectedServices.find(x => x.petId === pet.id)?.serviceId}
                            showCheckbox={false} />
                        <LineDivider marginBottom={20} marginTop={20} />
                    </Fragment>)}
                {hasMobileServiceFee &&
                    <>
                        <MobileServiceFeePriceRow fee={mobileServiceFee} customer={customer} />
                        <LineDivider marginBottom={20} marginTop={20} />
                    </>}
                {invitationCode && referralDiscount &&
                    <DiscountPriceRow
                        discount={referralDiscount}
                        discountLabel="First appointment discount"
                    />}
                <Button
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    disableElevation
                    onClick={async () => await onNext()}
                >
                    Book an appointment
                </Button>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}