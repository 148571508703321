
interface DiscountData {
    type: DiscountType,
    value: number
}

interface DiscountBase extends DiscountData {
    id: string
}

const QUALIFYING_RECURRENCE_INTERVALS = [1, 2, 3, 4];
const REFERRAL_DISCOUNT_VALUE = 40;
const REFERRAL_CREDIT_VALUE = 40;

class DiscountBase {
    constructor(id: string, data: DiscountData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): DiscountData {
        const { id, ...data } = this;
        return data;
    }
    static getDescription(type: DiscountType) {
        switch (type) {
            case DiscountType.firstOccurrence:
                return DiscountLabel.firstOccurrenceDiscount;
            case DiscountType.referral:
                return DiscountLabel.fistAppointmentDiscount;
            case DiscountType.firstTimeCustomer:
                return DiscountLabel.firstTimeCustomerDiscount;
            case DiscountType.code:
                return DiscountLabel.codeDiscount;
            default:
                console.warn("Discount type not found, using enum value as description instead", type);
                return type;
        }
    }
    static getTypeText = (type: DiscountType) => {
        switch (type) {
            case DiscountType.firstOccurrence:
                return "First groom";
            case DiscountType.referral:
                return "Referral";
            case DiscountType.firstTimeCustomer:
                return "First time customer";
            default:
                console.warn("Discount type not found, using enum value as description instead", type);
                return type;
        }
    };
    static apply(discountValues: DiscountData[], originalPrice: number) {
        const discountTotal = discountValues.reduce((partialSum, a) => +partialSum + a.value, 0);
        return originalPrice - discountTotal;
    }
    static qualifiesForRecurrenceDiscount = (interval?: number) => {
        if (!interval) {
            return false;
        }
        else {
            return QUALIFYING_RECURRENCE_INTERVALS.includes(interval);
        }
    }
    static getTypesForAfterFirstOccurrence = () => {
        return [DiscountType.manual, DiscountType.mobileServiceFee];
    }
}

enum DiscountType {
    //HACK: We renamed firstGroom to firstOccurrence for clarity, but we need to keep the old value for backwards compatibility.
    firstOccurrence = "first_groom",
    mobileServiceFee = "mobile_service_fee",
    referral = "referral",
    manual = "manual",
    firstTimeCustomer = "first_time_customer",
    code = "code",
}

enum DiscountLabel {
    referralProgramCredit = "Referral program credit",
    fistAppointmentDiscount = "First appointment discount",
    firstOccurrenceDiscount = "First groom discount",
    firstTimeCustomerDiscount = "First time customer discount",
    codeDiscount = "Discount code"
}

export { DiscountBase, DiscountType, DiscountLabel, REFERRAL_DISCOUNT_VALUE, REFERRAL_CREDIT_VALUE };
export type { DiscountData };