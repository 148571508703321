import { Breed } from "@marathon/client-side/entities/Breed";
import { PetInput } from "@marathon/client-side/entities/PetInput";
import PetFormWrapper from "../PetFormWrapper";
import { validateForm } from "../PetForm";

interface OnlineBookingStep3PetFormProps {
    onNext: () => Promise<void>,
    customerPetsAmount: number,
    pet: PetInput,
    isSavingPet: boolean,
    breeds: Breed[],
    handlePetChange: (x: PetInput) => void,
    formError: string
}

export default function OnlineBookingStep3PetForm({ onNext, customerPetsAmount, pet, breeds, isSavingPet, handlePetChange, formError }: OnlineBookingStep3PetFormProps) {
    const isValid = validateForm(pet);
    return (
        <PetFormWrapper
            pet={pet}
            handlePetChange={handlePetChange}
            onNext={onNext}
            lastPetIndex={customerPetsAmount}
            breeds={breeds}
            formError={formError}
            isLoading={isSavingPet}
            isInvalid={!isValid}
            isFromReturningCustomer
        />
    );
}