import { customerCreditCardsCollection, getNonEmptyData, getParentId } from "@marathon/client-side/database";
import { DocumentSnapshot, getDocs, query, where } from "firebase/firestore";
import { CreditCardBase, CreditCardData, CreditCardStatus } from "@marathon/common/entities/CreditCard";

const mapEntity = function (snapshot: DocumentSnapshot<CreditCardData>) {
    return new CreditCard(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class CreditCard extends CreditCardBase {
    static async search(customerId: string) {
        const collection = customerCreditCardsCollection(customerId);
        const customQuery = query(collection, where("status", "==", CreditCardStatus.Active));
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.map(x => mapEntity(x));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new CreditCard(id, customerId, data);
    }
}