import { customerNotificationsCollection } from "@marathon/client-side/database";
import { addDoc } from "firebase/firestore";
import { CustomerNotificationBase, CustomerNotificationStatus } from "@marathon/common/entities/CustomerNotification";

export class CustomerNotification extends CustomerNotificationBase {
    static async create(customerId: string, appointmentId: string) {
        const collection = customerNotificationsCollection(customerId);
        const data = {
            appointment_id: appointmentId,
            status: CustomerNotificationStatus.unsent
        };
        const newDocument = await addDoc(collection, data);
        return new CustomerNotification(newDocument.id, customerId, data);
    }
}