import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper } from "../shared/CommonWrappers";
import AddressForm, { AddressFormProps } from "../shared/AddressForm";
import { Box } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Customer } from "@marathon/client-side/entities/Customer";

interface ReturningEditAddressProps extends AddressFormProps {
    customer: Customer
}

const ReturningNewAddressForm = ({
    address,
    customer,
    handleChange,
    onAddressAutocompleteChange,
    onNext,
    isLoading,
    isGeocoding
}: ReturningEditAddressProps) => {
    const classes = useStyles();

    const renderSaveButton = () => {
        return (
            <Button
                disabled={!address.drive_time}
                onClick={onNext}
                variant="contained"
                className={classes.button}
                disableElevation
                showSpinner={isLoading || isGeocoding}
            >
                Save
            </Button>
        );
    };

    return (
        <OnlineBookingContentWrapper>
            <Box display="flex" flexDirection="column">
                <TitleWrapper title="Add New Address" fullWidth />
                <AddressForm
                    returningCustomer={customer}
                    address={address}
                    handleChange={handleChange}
                    onAddressAutocompleteChange={onAddressAutocompleteChange}
                    onNext={onNext}
                    isLoading={isLoading}
                    isGeocoding={isGeocoding}
                    renderReturningAddressButtons={renderSaveButton}
                    isSecondaryAddress
                />
            </Box>
        </OnlineBookingContentWrapper>
    );
};

export default ReturningNewAddressForm;