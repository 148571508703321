import { discountCodesCollection, getNonEmptyData } from "@marathon/client-side/database";
import { DiscountCodeBase, DiscountCodeData, DiscountCodeStatus } from "@marathon/common/entities/DiscountCode";
import { UpdateData, doc, updateDoc, query, where, DocumentSnapshot, getDocs } from "firebase/firestore";

const mapEntity = function (snapshot: DocumentSnapshot<DiscountCodeData>) {
    return new DiscountCode(snapshot.id, getNonEmptyData(snapshot));
};

export class DiscountCode extends DiscountCodeBase {
    static async update(id: string, data: UpdateData<DiscountCodeData>) {
        const reference = doc(discountCodesCollection, id);
        await updateDoc(reference, data);
    }
    async markAsUsed() {
        await DiscountCode.update(this.id, { status: DiscountCodeStatus.used });
    }
    static async getByCode(code: string) {
        const customQuery = query(discountCodesCollection,
            where("code", "==", code)
        );
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return null;
        }
        const discountCode = mapEntity(snapshot.docs[0]);
        return discountCode.isValid() ? discountCode : null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new DiscountCode(id, data);
    }
}
