
import { CustomerBase, CustomerData, CustomerStatus } from "@marathon/common/entities/Customer";
import { customersCollection, fetchEntitiesByIds, getNonEmptyData } from "@marathon/client-side/database";
import {
    doc, getDoc, getDocs, addDoc, updateDoc, query, where, limit, startAfter, orderBy,
    DocumentSnapshot, WhereFilterOp, OrderByDirection, UpdateData, deleteField, documentId, onSnapshot, QueryConstraint
} from "firebase/firestore";
import { ONLINE_BOOKING_ORIGIN, leadOutcomes, customerTypes } from "@marathon/common/constants";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { normalizeEmail, normalizePhone } from "@marathon/common/normalizationHelper";
import { Appointment } from "./Appointment";
import { Message } from "./Message";
import { MessageParentType } from "@marathon/common/entities/Message";
import { nanoid } from "nanoid/non-secure";
import { CustomerLog } from "./CustomerLog";
import { CustomerLogActivities } from "@marathon/common/entities/CustomerLog";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { CustomerNotification } from "./CustomerNotification";
import { CustomerInput } from "./CustomerInput";
import { CustomerOnboardingInput } from "./CustomerOnboardingInput";

const mapEntity = function (snapshot: DocumentSnapshot<CustomerData>) {
    return new Customer(snapshot.id, getNonEmptyData(snapshot));
};

const getCustomerType = (outcome?: string) => {
    return outcome === leadOutcomes.closed_won ? customerTypes.customer : customerTypes.lead;
};

const prepareData = (data: Partial<CustomerData>, original?: CustomerData) => {
    const currentUserFullName = DeviceStorageCache.getCurrentUserName() || ONLINE_BOOKING_ORIGIN;

    data.phone = data.phone ? normalizePhone(data.phone) : "";
    data.email = data.email ? normalizeEmail(data.email) : "";

    if (original) {
        data.updated_at = new Date();
        data.updated_by = currentUserFullName;

        const isBeingClosed =
            data.lead_info?.outcome === leadOutcomes.closed_won &&
            original.lead_info?.outcome !== leadOutcomes.closed_won;
        if (isBeingClosed) {
            data.closed_by = currentUserFullName;
        }

        const isOutcomeBeingUpdated = original.lead_info?.outcome !== data.lead_info?.outcome;
        if (isOutcomeBeingUpdated) {
            data.outcome_updated_by = currentUserFullName;
        }

        if (data.lead_info) {
            const isBeingResponded = data.lead_info.outcome && data.lead_info.outcome !== original.lead_info?.outcome;
            if (isBeingResponded) {
                data.lead_info.responded_by = currentUserFullName;
            }
        }
    }
    else {
        data.created_at = new Date();
        data.booked_by = currentUserFullName;
        data.new_message = false;
        data.status = CustomerStatus.active;
    }

    if (!original || original.type === customerTypes.lead) {
        data.type = getCustomerType(data.lead_info?.outcome);
    }

    const updateTwilioHub = !original || !data.twilio_hub_id;
    if (updateTwilioHub && data.address?.drive_time)
        data.twilio_hub_id = data.address.drive_time.hub_id;
};

const searchRecentInternal = async (filter: string[], itemsNumber: number, orders: string[][], afterDocument?: Document) => {
    let customQuery = query(
        customersCollection,
        where(filter[0], filter[1] as WhereFilterOp, filter[2]),
        limit(itemsNumber)
    );
    orders.forEach(order => {
        customQuery = query(customQuery, orderBy(order[0], order[1] as OrderByDirection));
    });
    if (afterDocument)
        customQuery = query(customQuery, startAfter(afterDocument));
    const snapshot = await getDocs(customQuery);
    return {
        customers: snapshot.docs.map(x => mapEntity(x)),
        lastDocument: snapshot.docs.length ? snapshot.docs[snapshot.docs.length - 1] : undefined
    };
};

export class Customer extends CustomerBase {
    static async doesEmailExist(email: string, customerId?: string) {
        if (!email) return false;
        let customQuery = query(
            customersCollection,
            where("email", "==", normalizeEmail(email)),
            where("status", "==", CustomerStatus.active)
        );
        if (customerId) {
            customQuery = query(customQuery, where(documentId(), "!=", customerId));
        }
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.length > 0;
    }
    static async doesPhoneExist(phone: string, customerId?: string) {
        if (!phone) return false;
        let customQuery = query(
            customersCollection,
            where("phone", "==", normalizePhone(phone)),
            where("status", "==", CustomerStatus.active)
        );
        if (customerId) {
            customQuery = query(customQuery, where(documentId(), "!=", customerId));
        }
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.length > 0;
    }
    static async getById(id: string) {
        const reference = doc(customersCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async searchByIds(ids: string[]) {
        return await fetchEntitiesByIds(customersCollection, ids, mapEntity);
    }
    static async getByPhone(phone: string) {
        const customQuery = query(customersCollection,
            where("phone", "==", normalizePhone(phone)),
            where("status", "==", CustomerStatus.active)
        );
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return null;
        }
        return mapEntity(snapshot.docs[0]);
    }
    static async getByEmail(email: string) {
        const customQuery = query(customersCollection,
            where("email", "==", normalizeEmail(email)),
            where("status", "==", CustomerStatus.active)
        );
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return null;
        }
        return mapEntity(snapshot.docs[0]);
    }
    static async createFromInput(input: CustomerInput) {
        const data = this.getDataFromInput(input);
        prepareData(data);
        const reference = await addDoc(customersCollection, data);
        return reference.id;
    }
    static async updateFromInput(customerId: string, input: CustomerInput) {
        const data = this.getDataFromInput(input);
        const reference = doc(customersCollection, customerId);
        const original = await getDoc(reference);
        prepareData(data, original.data());
        await updateDoc(reference, data);
        return customerId;
    }
    static async createFromOnboardingInput(input: CustomerOnboardingInput) {
        const data = this.getDataFromOnboardingInput(input);
        prepareData(data);
        const reference = await addDoc(customersCollection, data);
        return reference.id;
    }
    static async updateFromOnboardingInput(customerId: string, input: CustomerOnboardingInput) {
        const data = this.getDataFromOnboardingInput(input);
        const reference = doc(customersCollection, customerId);
        const original = await getDoc(reference);
        prepareData(data, original.data());
        console.log("Updating with data", { data });
        await updateDoc(reference, data);
        return customerId;
    }
    private static getDataFromInput(input: CustomerInput) {
        const data: Partial<CustomerData> = {
            firstname: input.firstname.trim(),
            lastname: input.lastname.trim(),
            phone: normalizePhone(input.phone),
            email: normalizeEmail(input.email),
            address: input.address,
            notes: input.notes,
            manager_notes: input.manager_notes,
            disallow_chatbot_interaction: input.disallow_chatbot_interaction,
            skip_mobile_service_fee: input.skip_mobile_service_fee,
            reminder_sms_opt_out: input.reminder_sms_opt_out,
            marketing_sms_opt_out: input.marketing_sms_opt_out,
            marketing_email_opt_out: input.marketing_email_opt_out,
            blacklist: input.blacklist,
            postcard_opt_out: input.postcard_opt_out,
            text_ok: input.text_ok,
            lead_info: input.lead_info,
            safety_accepted: input.safety_accepted
        };
        return data;
    }
    private static getDataFromOnboardingInput(input: CustomerOnboardingInput) {
        const data: Partial<CustomerData> = {
            firstname: input.firstname.trim(),
            lastname: input.lastname.trim(),
            phone: normalizePhone(input.phone),
            email: normalizeEmail(input.email),
            address: input.address,
            notes: input.notes,
            text_ok: input.text_ok,
            lead_info: input.lead_info
        };
        return data;
    }
    static async createFromOnlineBooking(getStartedData: InitialLeadData, uid: string) {
        const data = Customer.getDefaultData();

        data.firstname = getStartedData.firstName;
        data.lastname = getStartedData.lastName;
        data.phone = getStartedData.phoneNumber;
        data.email = getStartedData.email;
        data.tos_pp_ok = getStartedData.termsOfServiceOk;
        data.text_ok = getStartedData.textOk;

        data.anonymous_uid = uid;

        if (!data.lead_info)
            throw new Error("Lead info is required at this point");
        data.lead_info.origin = ONLINE_BOOKING_ORIGIN;
        data.lead_info.outcome = leadOutcomes.open_address_page;
        data.lead_info.open_conversation = true;

        prepareData(data);
        await addDoc(customersCollection, data);
    }
    static async updateFromOnlineBooking(id: string, data: UpdateData<CustomerData>) {
        data.updated_by = ONLINE_BOOKING_ORIGIN;
        data.updated_at = new Date();
        const reference = doc(customersCollection, id);
        await updateDoc(reference, data);
    }
    static async updateAnonymousUid(id: string, anonymous_uid: string) {
        const reference = doc(customersCollection, id);
        await updateDoc(reference, { anonymous_uid });
    }
    static async remove(id: string) {
        const reference = doc(customersCollection, id);
        await updateDoc(reference, { status: CustomerStatus.deleted });
    }
    async updateVipNotes(vip_notes: string) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { vip_notes });
    }
    async updateParkingNotes(parkingNotes: string) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { "address.parking_notes": parkingNotes });
    }
    async updateStandbyExpiration(until?: Date) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, {
            in_standby: true,
            standby_expiration: until ? { until } : deleteField(),
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        });
    }
    async updateBlacklistNotes(blacklist_notes: string) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { blacklist_notes });
    }
    async markLeadAsResponded() {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { "lead_info.new_lead": false });
    }
    async toggleOpenConversation() {
        const reference = doc(customersCollection, this.id);
        let toUpdate: UpdateData<Customer>;
        if (this.lead_info?.open_conversation) {
            toUpdate = {
                "lead_info.open_conversation": false,
                updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined,
                "lead_info.assigned_user_id": deleteField(),
                "lead_info.assigned_team_id": deleteField(),

            };
            await this.checkAndDisableChatbot();
        }
        else {
            toUpdate = {
                "lead_info.open_conversation": true,
                updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined,
            };
        }
        await updateDoc(reference, toUpdate);
    }
    async toggleStarred(currentValue: boolean) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { starred: !currentValue });
    }
    async toggleStandby(currentValue: boolean) {
        const reference = doc(customersCollection, this.id);
        const toUpdate = currentValue
            ? { in_standby: false, standby_expiration: deleteField() }
            : { in_standby: true };
        await updateDoc(reference, toUpdate);
    }
    async toggleVipMark(currentValue: boolean) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { vip: !currentValue });
    }
    async toggleBlacklist(currentValue: boolean) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { blacklist: !currentValue });
    }
    static async searchAllWithCurrentChatbot() {
        const snapshot = await getDocs(query(customersCollection,
            where("current_chatbot_activity_id", "!=", "")));
        return snapshot.docs.map(x => mapEntity(x));
    }
    async toggleChatbotEnabled(initialMessageCount?: number, context?: string) {
        if (this.current_chatbot_activity_id) {
            await this.checkAndDisableChatbot();
        }
        else {
            await CallableFunctions.artificialIntelligence.enableChatbot(
                this.id,
                initialMessageCount ?? 0,
                DeviceStorageCache.getNonEmptyCurrentUserName(),
                context
            );
        }
    }
    async checkAndDisableChatbot() {
        await this.removeLiveAgentRequestedMark();
        if (this.current_chatbot_activity_id) {
            const reference = doc(customersCollection, this.id);
            await updateDoc(reference, { current_chatbot_activity_id: deleteField() });
            await CustomerLog.create(this.id, {
                description: CustomerLogActivities.chatbotTurnedOff,
                date: new Date(),
                user: DeviceStorageCache.getNonEmptyCurrentUserName()
            });
        }
    }
    async snoozeConversation(until?: Date) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, {
            "lead_info.open_conversation": false,
            snoozed_conversation: until ? { until } : deleteField(),
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        });
    }
    async markNewMessage(status = false) {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { new_message: status });
    }
    async removeLiveAgentRequestedMark() {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { live_agent_requested: deleteField() });
    }
    async updateTwilioHub(twilioHubId?: string) {
        const reference = doc(customersCollection, this.id);
        const data = {
            twilio_hub_id: twilioHubId || deleteField()
        };
        await updateDoc(reference, data);
    }
    async getInvitationCode() {
        if (this.invitation_code) {
            return this.invitation_code;
        }
        else {
            const code = nanoid(8);
            const reference = doc(customersCollection, this.id);
            await updateDoc(reference, { invitation_code: code });
            return code;
        }
    }
    static async getByInvitationCode(invitationCode: string) {
        const customQuery = query(customersCollection,
            where("invitation_code", "==", invitationCode),
        );
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return null;
        }
        return mapEntity(snapshot.docs[0]);
    }
    async cleanOnlineBookingSession() {
        const reference = doc(customersCollection, this.id);
        await updateDoc(reference, { open_online_booking_session: deleteField() });
    }
    static async assignUser(customerId: string, userId: string, optionalNote: string) {
        const reference = doc(customersCollection, customerId);
        const toUpdate: UpdateData<CustomerData> = {
            "lead_info.assigned_user_id": userId,
            "lead_info.open_conversation": true,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        };

        if (optionalNote) {
            await Message.createManagerNote({
                collectionType: MessageParentType.Customers,
                parentId: customerId,
                content: optionalNote
            });
        }

        await updateDoc(reference, toUpdate);
    }
    static async markAsUnassigned(customerId: string) {
        const reference = doc(customersCollection, customerId);
        await updateDoc(reference, {
            "lead_info.assigned_user_id": deleteField(),
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        });
    }
    static async assignTeam(customerId: string, teamId: string) {
        const reference = doc(customersCollection, customerId);
        const toUpdate: UpdateData<CustomerData> = {
            "lead_info.assigned_team_id": teamId,
            "lead_info.open_conversation": true,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        };

        await updateDoc(reference, toUpdate);
    }
    static async markAsUnassignedTeam(customerId: string) {
        const reference = doc(customersCollection, customerId);
        await updateDoc(reference, {
            "lead_info.assigned_team_id": deleteField(),
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        });
    }
    static async searchRecentCustomers(limit: number, startAfter?: Document) {
        return await searchRecentInternal(
            ["type", "!=", "Lead"],
            limit,
            [
                ["type", "asc"],
                ["created_at", "desc"]
            ],
            startAfter
        );
    }
    static async searchRecentLeads(limit: number, startAfter?: Document) {
        return await searchRecentInternal(
            ["type", "==", "Lead"],
            limit,
            [
                ["created_at", "desc"]
            ],
            startAfter
        );
    }
    static async searchUpcomingAppointmentOrOccurrence(customerId: string) {
        const futureAppointments = await Appointment.searchFuture(customerId);

        return (
            futureAppointments.length > 0
                ? futureAppointments.sortByFieldAscending(x => x.start_time)[0]
                : null
        );
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new Customer(id, data);
    }
    private static listenByLastMessages(queries: QueryConstraint[], callback: (data: Customer[]) => void) {
        const constraints = [
            where("status", "==", CustomerStatus.active),
            ...queries
        ];

        const customerQuery = query(customersCollection, ...constraints);
        return onSnapshot(customerQuery, (querySnapshot) => {
            const customers: Customer[] = [];
            querySnapshot.forEach(doc => {
                customers.push(mapEntity(doc));
            });
            customers.sort((a, b) =>
                (b.last_message?.getTime() || 0) -
                (a.last_message?.getTime() || 0)
            );
            callback(customers);
        });
    }
    static listenByLastMessagesOpen(callback: (data: Customer[]) => void) {
        const constraints = [
            where("lead_info.open_conversation", "==", true)
        ];
        return this.listenByLastMessages(constraints, callback);
    }
    static listenByLastMessagesSnoozed(callback: (data: Customer[]) => void) {

        const constraints = [
            where("snoozed_conversation.until", ">", new Date())
        ];
        return this.listenByLastMessages(constraints, callback);
    }
    static listenChanges(id: string, callback: (data: Customer) => void) {
        const reference = doc(customersCollection, id);
        return onSnapshot(reference, snapshot => {
            if (!snapshot.exists())
                return;
            callback(mapEntity(snapshot));
        });
    }
    static async notifyAppointmentChange(appointmentId: string) {
        const appointment = await Appointment.getById(appointmentId);
        if (!appointment)
            throw new Error(`Appointment ${appointmentId} not found`);

        await CustomerNotification.create(appointment.customer.id, appointmentId);
    }
}

export interface InitialLeadData {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    termsOfServiceOk: boolean,
    textOk: boolean
}