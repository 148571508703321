import { CustomerLogBase, CustomerLogData } from "@marathon/common/entities/CustomerLog";
import { addDoc, DocumentSnapshot, query, where, orderBy, limit, startAfter, getDocs, onSnapshot } from "firebase/firestore";
import { customerLogsCollection, getNonEmptyData, getParentId } from "@marathon/client-side/database";

const mapEntity = function (snapshot: DocumentSnapshot<CustomerLogData>) {
    return new CustomerLog(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class CustomerLog extends CustomerLogBase {
    static async create(customerId: string, data: CustomerLogData) {
        const newDocument = await addDoc(customerLogsCollection(customerId), data);
        return new CustomerLog(newDocument.id, customerId, data);
    }
    static async searchByDate(
        customerId: string,
        fromDate: Date,
        toDate: Date,
        pageSize?: number,
        afterDocument?: Document
    ) {
        const collection = customerLogsCollection(customerId);
        let customQuery = query(collection,
            where("date", ">=", fromDate),
            where("date", "<", toDate),
            orderBy("date", "desc")
        );

        if (pageSize)
            customQuery = query(customQuery, limit(pageSize));

        if (afterDocument)
            customQuery = query(customQuery, startAfter(afterDocument));

        const snapshot = await getDocs(customQuery);
        return {
            logs: snapshot.docs.map(x => mapEntity(x)),
            lastDocument: snapshot.docs.length ? snapshot.docs[snapshot.docs.length - 1] : undefined
        };
    }
    static listenChanges(
        customerId: string,
        documentCount: number,
        callback: (logs: CustomerLog[]) => void): () => void {
        const collection = customerLogsCollection(customerId);
        const changesQuery = query(collection,
            orderBy("date", "desc"),
            limit(documentCount));
        return onSnapshot(changesQuery, snapshot => {
            const logs: CustomerLog[] = [];
            snapshot.forEach(x => logs.push(mapEntity(x)));
            callback(logs);
        });
    }
}
