import { Fragment, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Button } from "@marathon/web/components/Button";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper, FieldsetWrapper } from "../shared/CommonWrappers";
import { cancelOrUpdateRecurrenceMessage } from "../shared/CancelAppointmentModal";
import FindSuggestions from "../shared/FindSuggestions";
import { useFetchSuggestions } from "../shared/useFetchSuggestions";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { SecondaryAddress } from "@marathon/client-side/entities/SecondaryAddress";
import { getIsAuthenticated } from "../shared/authHelper";
import { Customer } from "@marathon/client-side/entities/Customer";
import { AddressSelector, AddressSelectorOriginApp } from "@marathon/web/components/address/AddressSelector";
import LocalDate from "@marathon/common/LocalDate";
import { TimeFilterOptionKey } from "@marathon/common/constants";
import { Pet } from "@marathon/client-side/entities/Pet";
import { PetServiceSelection } from "@marathon/web/components/onlineBooking/PetServiceSelection";
import LineDivider from "../shared/LineDivider";
import { TimeZone } from "@marathon/common/timeZoneHelper";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { errorMessages } from "@marathon/common/constants";
import { logErrorAndShowTitleOnlyAlert } from "@marathon/web/helpers/errorHandlingHelper";
import { Appointment } from "@marathon/client-side/entities/Appointment";
import { AppointmentInput } from "@marathon/client-side/entities/AppointmentInput";
import { AppointmentPetInput } from "@marathon/client-side/entities/AppointmentPetInput";
import { Groomer } from "@marathon/client-side/entities/Groomer";
import { Service } from "@marathon/client-side/entities/Service";
import { Hub } from "@marathon/client-side/entities/Hub";
import { Breed } from "@marathon/client-side/entities/Breed";
import { AppointmentData } from "@marathon/common/entities/Appointment";

interface AppointmentChangeProps {
    appointmentOrOccurrence: AppointmentOrOccurrence,
    customer: Customer,
    addresses: (SecondaryAddress | CustomerAddressData)[],
    pets: Pet[],
    services: Service[],
    groomers: Groomer[],
    hubs: Hub[],
    breeds: Breed[],
    handleUpdateAppointment: (appointmentOrOccurrence: AppointmentOrOccurrence, toUpdate: Partial<AppointmentData>) => Promise<void>,
    goBack: () => void,
}

const AppointmentChange = ({
    appointmentOrOccurrence,
    customer,
    addresses,
    pets,
    services,
    groomers,
    hubs,
    breeds,
    goBack,
    handleUpdateAppointment
}: AppointmentChangeProps) => {

    const appointmentAddress = addresses.find(x =>
        x instanceof SecondaryAddress
            ? x.id === appointmentOrOccurrence.customer.secondary_address_id
            : !appointmentOrOccurrence.customer.secondary_address_id
    );
    if (!appointmentAddress)
        throw new Error(`Address not found for appointment ${appointmentOrOccurrence.id}`);

    const classes = useStyles();
    const [selectedAddress, setSelectedAddress] = useState<CustomerAddressData | SecondaryAddress>(appointmentAddress || customer.address);
    const isOccurrence = appointmentOrOccurrence.occurrenceIndex !== undefined || appointmentOrOccurrence.occurrenceIndex === 0;
    const [showPetAddressSelector, setShowPetAddressSelector] = useState(!isOccurrence);
    const [showFindSuggestion, setShowFindSuggestion] = useState(false);
    const [specificDate, setSpecificDate] = useState<LocalDate | null>(null);
    const [selectedGroomerId, setSelectedGroomerId] = useState<string | null>(null);
    const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeFilterOptionKey | null>(null);
    const [bookedSuggestion, setBookedSuggestion] = useState<BookingSuggestion | null>(null);
    const [selectedServices, setSelectedServices] = useState<{ petId: string, serviceId: string }[]>(appointmentOrOccurrence.selected_pets.map(pet => ({ serviceId: pet.serviceId, petId: pet.petId })));
    const [isUpdatingAppointment, setIsUpdatingAppointment] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const handleChangeAppointmentClick = () => {
        setShowPetAddressSelector(true);
    };

    const handleClickFindSuggestions = () => {
        setShowPetAddressSelector(false);
        setShowFindSuggestion(true);
    };

    const handleChangeAppointment = async () => {
        if (!bookedSuggestion)
            throw new Error("No suggestion selected to book");

        const selectedGroomer = groomers.find(x => x.id === bookedSuggestion.groomer.id);

        if (!selectedGroomer)
            throw new Error(`Groomer ${selectedGroomerId} not found`);

        const secondaryAddress = selectedAddress instanceof SecondaryAddress ? selectedAddress : undefined;

        const appointmentInput = AppointmentInput.getFromAppointment(appointmentOrOccurrence, services);
        appointmentInput.selectedPets = Appointment.getSelectedPets(selectedServices, pets).map(x => new AppointmentPetInput(x));
        appointmentInput.startTime = bookedSuggestion.arrivalTime;

        const newAppointmentData = Appointment.getAppointmentToUpdate(appointmentInput, selectedGroomer, customer, services, pets, secondaryAddress);
        await handleUpdateAppointment(appointmentOrOccurrence, newAppointmentData);
    };

    const handleSubmit = async () => {
        try {
            setIsUpdatingAppointment(true);
            await handleChangeAppointment();
        }
        catch (error) {
            console.log(error);
            logErrorAndShowTitleOnlyAlert(error, errorMessages.try_again_or_call_us);
        }
        finally {
            setIsUpdatingAppointment(false);
            goBack();
        }
    };

    const { suggestions, isFetchingSuggestions } = useFetchSuggestions({
        customerId: customer.id,
        selectedServices,
        specificDate: specificDate || undefined,
        selectedGroomerId: selectedGroomerId || undefined,
        selectedTimeFilter: selectedTimeFilter || undefined,
        services,
        selectedAddress: selectedAddress || customer.address,
        isAuthenticated: getIsAuthenticated()
    });

    const handleSelectedAddressChange = (address: CustomerAddressData | SecondaryAddress) => {
        setSelectedAddress(address);
    };

    const getAddressTimeZone = useCallback(() => {
        return hubs.find(x => x.id === selectedAddress?.drive_time?.hub_id)?.time_zone ?? TimeZone.PacificTime;
    }, [hubs, selectedAddress?.drive_time?.hub_id]);

    const handlePetSelection = (e: React.ChangeEvent<HTMLInputElement>, pet: Pet) => {
        try {
            const petService = e.target.checked
                ? selectedServices.concat({ petId: pet.id, serviceId: pet.getDefaultServiceId() })
                : selectedServices.filter(x => x.petId !== pet.id);
            setSelectedServices(petService);
        }
        catch (error) {
            logErrorAndShowTitleOnlyAlert(error, errorMessages.try_again_or_call_us);
        }
    };

    const showTitle = !(isFetchingSuggestions && showFindSuggestion);

    return (
        <OnlineBookingContentWrapper>
            {showTitle && <TitleWrapper title="Change Appointment" />}
            <ContentWrapper>
                {isOccurrence && !showFindSuggestion && !showPetAddressSelector &&
                    <>
                        {cancelOrUpdateRecurrenceMessage}
                        <Box mt={2}>
                            <Button
                                variant="outlined"
                                className={classes.buttonSecondary}
                                onClick={() => handleChangeAppointmentClick()}
                                showSpinner={isUpdatingAppointment}
                                disableElevation>
                                Change this appointment
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.buttonSecondary}
                                onClick={() => goBack()}
                                disableElevation>
                                Back
                            </Button>
                        </Box>
                    </>}
                {showPetAddressSelector &&
                    <>
                        {addresses.length > 1 &&
                            <Box className="selected-address-row-container">
                                <AddressSelector
                                    addresses={addresses}
                                    handleSelectedAddressChange={handleSelectedAddressChange}
                                    selectedAddress={selectedAddress || undefined}
                                    hubs={hubs}
                                    originApp={AddressSelectorOriginApp.Customer}
                                />
                            </Box>}
                        {pets.length > 0 &&
                            <FieldsetWrapper>
                                {pets.map((pet, idx) => (
                                    <Fragment key={idx}>
                                        <LineDivider
                                            marginTop={idx === 0 ? 0 : 20}
                                            marginBottom={20}
                                        />
                                        <PetServiceSelection
                                            key={idx}
                                            pet={pet}
                                            selectedServiceId={selectedServices.find(x => x.petId === pet.id)?.serviceId}
                                            services={services}
                                            breeds={breeds}
                                            handleChangeSelectedService={(petId: string, serviceId: string) => setSelectedServices(selectedServices.map(x => x.petId === petId ? { ...x, serviceId } : x))}
                                            checked={selectedServices.some(x => x.petId === pet.id)}
                                            onChange={e => handlePetSelection(e, pet)}
                                        />
                                    </Fragment>
                                ))}
                            </FieldsetWrapper>}
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.button}
                            onClick={() => handleClickFindSuggestions()}
                            disabled={selectedServices.length === 0}
                            disableElevation>
                            Find appointments
                        </Button>
                    </>}
                <Box>
                    {showFindSuggestion && !showConfirmationDialog &&
                        <FindSuggestions
                            isLoading={isFetchingSuggestions}
                            suggestions={suggestions}
                            groomers={groomers.filter(x => x.hub_id === selectedAddress?.drive_time?.hub_id && !x.hasRestrictions(selectedServices.map(x => x.serviceId)))}
                            specificDate={specificDate}
                            setSpecificDate={setSpecificDate}
                            selectedGroomerId={selectedGroomerId}
                            setSelectedGroomerId={setSelectedGroomerId}
                            selectedTimeFilter={selectedTimeFilter}
                            setSelectedTimeFilter={setSelectedTimeFilter}
                            onBookAppointmentClick={() => setShowConfirmationDialog(true)}
                            setBookedSuggestion={setBookedSuggestion}
                            customerTimeZone={getAddressTimeZone()}
                            isFromAppointmentChange={true}
                        />}
                </Box>
                {showConfirmationDialog &&
                    <Box mt={2} fontFamily="Plain Medium">
                        <span>Are you sure you want to modify this appointment?</span>
                        <Button
                            variant="outlined"
                            className={classes.buttonSecondary}
                            onClick={() => handleSubmit()}
                            showSpinner={isUpdatingAppointment}
                            disableElevation>
                            Yes, modify this appointment
                        </Button>
                        <Button
                            variant="outlined"
                            className={classes.buttonSecondary}
                            onClick={() => setShowConfirmationDialog(false)}
                            disableElevation>
                            No, keep this appointment
                        </Button>
                    </Box>}
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default AppointmentChange;
