import { getMobileServiceFeePrice } from "../mobileServiceFeeHelper";
import { MobileServiceFeeConfiguration } from "./Configuration";
import { CustomerBase } from "./Customer";
import { PetBase } from "./Pet";

interface ServiceData {
    name: string,
    description: string,
    customers_description?: string,
    short_description?: string,
    long_description?: string,
    estimated_time: number,
    offer_in_chatbot?: true,
    tied_breed: boolean,
    status: ServiceStatus,
    hide_from_groomer?: true,
    allow_time_override?: true,
    is_addon?: true
}

interface ServiceBase extends ServiceData {
    id: string
}

class ServiceBase {
    constructor(id: string, data: ServiceData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): ServiceData {
        const { id, ...data } = this;
        return data;
    }
    getAcronym() {
        return this.name.slice(0, 2);
    }
    getEndingAcronym() {
        return this.name.split("-").pop() ?? "";
    }
    static getServicesDuration(services: ServiceBase[], selectedServiceIds: string[]) {
        return selectedServiceIds.reduce((accumulated, serviceId) => {
            const service = services.find(s => s.id === serviceId);
            return accumulated + (service?.estimated_time || 0);
        }, 0);
    }
    static getServicesLabel(services: ServiceBase[], selectedServiceIds: string[]) {
        return services.filter(x => selectedServiceIds.some(serviceId => serviceId === x.id)).map(x => x.name).join(", ");
    }
    static getServicesGrossPrice(
        selectedServices: { petId: string, serviceId: string }[],
        pets: PetBase[]
    ) {
        return selectedServices.reduce((accumulated, selectedServiceByPet) => {
            const pet = pets.find(x => selectedServiceByPet.petId === x.id);
            if (!pet || !pet.services)
                return 0;
            const service = pet.services.find(s => s.id === selectedServiceByPet.serviceId);

            return accumulated + (service?.price || 0);
        }, 0);
    }
    static getServicesFinalPrice(
        selectedServices: { petId: string, serviceId: string }[],
        pets: PetBase[],
        customer: CustomerBase,
        mobileServiceFee: MobileServiceFeeConfiguration) {
        let totalServicePrice = ServiceBase.getServicesGrossPrice(selectedServices, pets);
        if (mobileServiceFee.is_enabled && !customer.skip_mobile_service_fee)
            totalServicePrice += getMobileServiceFeePrice(mobileServiceFee, customer);
        return totalServicePrice;
    }
    static getDemattingServiceIds() {
        return [DEMATTING_30MIN_SERVICE_ID, DEMATTING_60MIN_SERVICE_ID];
    }
    formatAcronymName() {
        const acronymName = GROOMER_UI_ACRONYMS_MAP[this.getEndingAcronym() as keyof typeof GROOMER_UI_ACRONYMS_MAP];
        return acronymName ?? this.name;
    }
    isRelevantForGroomerUI() {
        return Object.keys(GROOMER_UI_ACRONYMS_MAP).includes(this.getEndingAcronym());
    }
    static sortRelevantForGroomerUI(a: ServiceBase, b: ServiceBase) {
        const aIsRelevant = a.isRelevantForGroomerUI();
        const bIsRelevant = b.isRelevantForGroomerUI();

        if (aIsRelevant && bIsRelevant) {
            return a.name.localeCompare(b.name);
        } else if (aIsRelevant) {
            return -1;
        } else if (bIsRelevant) {
            return 1;
        } else {
            return 0;
        }
    }
}

enum ServiceStatus {
    active = "active",
    hidden = "hidden"
}

const DEMATTING_30MIN_SERVICE_ID = "4EGBGYUDDWPRGVQQ3V24WZDR";
const DEMATTING_60MIN_SERVICE_ID = "Foxzwb6tfFPRF8WCShyK";

const GROOMER_UI_ACRONYMS_MAP = {
    B: "Bath",
    FFS: "Face, Feet, Sani",
    HC: "Full Haircut",
    D: "Deshed"
};

export { ServiceBase, ServiceStatus };
export type { ServiceData };