import { Box } from "@mui/material";
import { Credit } from "@marathon/client-side/entities/Credit";
import { DiscountLabel } from "@marathon/common/entities/Discount";

interface Props {
    credits?: Credit[]
}

export const CreditPriceRow = ({ credits }: Props) => {
    return (
        <Box className="pricing-row-container">
            <Box className="pricing-row-label">
                {`${DiscountLabel.referralProgramCredit}`}
            </Box>
            <Box className="pricing-row-amount">
                {`- $${credits?.reduce((partialSum, cre) => partialSum + cre.value, 0)}`}
            </Box>
        </Box>
    );
};