interface ServicePriceData {
    service_id: string,
    price: number
}

interface PricingPresetData {
    name: string,
    services: ServicePriceData[]
}

interface PricingPresetBase extends PricingPresetData {
    id: string
}

class PricingPresetBase {
    constructor(id: string, data: PricingPresetData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): PricingPresetData {
        const { id, ...data } = this;
        return data;
    }
}

export { PricingPresetBase };
export type { PricingPresetData, ServicePriceData };