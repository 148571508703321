import { AppointmentCredit } from "@marathon/common/entities/Appointment";

interface CreditData {
    value: number,
    customer_referred: string,
    customer_referred_id: string,
    was_used: boolean,
}

interface CreditBase extends CreditData {
    id: string,
    customerId: string
}

class CreditBase {
    constructor(id: string, customerId: string, data: CreditData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    toData(): CreditData {
        const { id, customerId, ...data } = this;
        return data;
    }
    static sum(creditsValues: AppointmentCredit[]) {
        return creditsValues.reduce((partialSum, a) => +partialSum + a.value, 0);
    }
    static apply(creditsValues: AppointmentCredit[], originalPrice: number) {
        const creditDiscount = creditsValues.reduce((partialSum, a) => +partialSum + a.value, 0);
        return originalPrice - creditDiscount;
    }
}

export { CreditBase };
export type { CreditData };