import { discountsCollection, getNonEmptyData } from "@marathon/client-side/database";
import { DocumentSnapshot, getDocs } from "firebase/firestore";
import { DiscountBase, DiscountData } from "@marathon/common/entities/Discount";

const mapEntity = function (snapshot: DocumentSnapshot<DiscountData>) {
    return new Discount(snapshot.id, getNonEmptyData(snapshot));
};

export class Discount extends DiscountBase {
    static async search() {
        const snapshot = await getDocs(discountsCollection);
        return snapshot.docs.map(x => mapEntity(x));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(type: any) {
        const { id, ...data } = type;
        return new Discount(id, data);
    }
}