import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Box, FormHelperText } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { PetForm } from "./PetForm";
import { OnlineBookingContentWrapper } from "./OnlineBookingContentWrapper";
import { Breed } from "@marathon/client-side/entities/Breed";
import { PetInput } from "@marathon/client-side/entities/PetInput";
import { useReward } from "react-rewards";
import { ContentWrapper, TitleWrapper } from "./shared/CommonWrappers";

interface PetFormWrapperProps {
    onNext: () => void,
    lastPetIndex: number,
    pet: PetInput,
    isLoading: boolean,
    breeds: Breed[],
    handlePetChange: (x: PetInput) => void,
    formError: string,
    isInvalid?: boolean,
    isFromReturningCustomer?: boolean
}

export default function PetFormWrapper({ onNext, lastPetIndex, pet, breeds, isLoading, handlePetChange, formError, isInvalid, isFromReturningCustomer }: PetFormWrapperProps) {
    const classes = useStyles();
    const [showAnimation, setShowAnimation] = useState(false);
    const rewardElementId = "petNameReward";

    const { reward } = useReward(rewardElementId, "confetti", {
        spread: 111,
        zIndex: 9,
        startVelocity: 17,
        lifetime: 114,
        elementCount: 40,
        elementSize: 8,
        decay: 0.94
    });

    const [petName, setPetName] = useState(pet.name || `Dog #${lastPetIndex + 1}`);

    const handleBlurPetName = () => {
        const newPetName = pet?.name;
        setPetName(newPetName || `Dog #${lastPetIndex + 1}`);
        if (newPetName && newPetName !== petName) {
            setShowAnimation(true);
            setTimeout(() => reward(), 250);
        }
    };

    useEffect(() => {
        setPetName(pet?.name || `Dog #${lastPetIndex + 1}`);
        setShowAnimation(false);
        //HACK: We only want this useEffect to be triggered when editingPetIndex changed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastPetIndex]);

    const renderPetName = () => {
        if (showAnimation) {
            return (
                <motion.span
                    key={petName}
                    initial={{ y: -30, opacity: 0 }}
                    exit={{ y: -30, opacity: 0 }}
                    animate={{ y: 20, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <span id={rewardElementId}>
                        {petName}
                    </span>
                </motion.span>
            );
        }
        else {
            return <>{petName}</>;
        }
    };

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper>
                <h1>Tell us about {renderPetName()}</h1>
            </TitleWrapper>
            <ContentWrapper>
                <PetForm
                    pet={pet}
                    handleChange={newValue => handlePetChange(newValue)}
                    breeds={breeds}
                    showName={true}
                    handleBlurName={handleBlurPetName}
                />
                {formError &&
                    <Box mt={2}>
                        <FormHelperText>{formError}</FormHelperText>
                    </Box>}
                <Button
                    onClick={onNext}
                    variant="contained"
                    className={classes.button}
                    showSpinner={isLoading}
                    disabled={isLoading || isInvalid}
                    disableElevation
                >
                    {isFromReturningCustomer ? "Save" : "Continue"}
                </Button>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}