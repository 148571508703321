import { TimeZone } from "../timeZoneHelper";

interface DailyAnnouncementData {
    sent_date: Date,
    time_zone: TimeZone,
    messages: {
        customerId: string,
        messageId: string
    }[]
}

interface DailyAnnouncementBase extends DailyAnnouncementData {
    id: string,
    groomerId: string
}

class DailyAnnouncementBase {
    constructor(id: string, groomerId: string, data: DailyAnnouncementData) {
        this.id = id;
        this.groomerId = groomerId;
        Object.assign(this, data);
    }
    toData(): DailyAnnouncementData {
        const { id, groomerId, ...data } = this;
        return data;
    }
}
export { DailyAnnouncementBase };
export type { DailyAnnouncementData };