import { AppointmentBase, AppointmentData } from "./Appointment";
import { DriveTimeData } from "./DriveTime";
import { RecurrenceData } from "./Recurrence";
import { areOverlapping, getOverlappingDuration } from "../timeHelper";

interface Occurrence extends RecurrenceData {
    id: string
    occurrenceIndex: number
    drive_time?: DriveTimeData
}

interface AppointmentOrOccurrence extends AppointmentData {
    id: string
    occurrenceIndex?: number
}

class Occurrence {
    constructor(id: string, occurrenceIndex: number, data: RecurrenceData) {
        this.id = id;
        this.occurrenceIndex = occurrenceIndex;
        Object.assign(this, data);
    }
    toAppointmentData(): AppointmentData {
        const { id, occurrenceIndex, drive_time, exceptions, is_new_recurrent_customer, ...data } = this;
        return data;
    }
    toRecurrenceData(): RecurrenceData {
        const { id, occurrenceIndex, ...data } = this;
        return data;
    }
    isFirstOccurrenceOfNewRecurrentCustomer() {
        return (
            !!this.is_new_recurrent_customer && this.occurrenceIndex === 0
        );
    }
    createAppointmentData(toUpdate: Partial<AppointmentData>): AppointmentData {
        const data = this.toAppointmentData();
        Object.assign(data, toUpdate);
        data.exception_created_at = new Date();
        data.recurrence_id = this.id;
        if (this.isFirstOccurrenceOfNewRecurrentCustomer()) {
            data.is_new_customer = true;
        }
        return data;
    }
    overlapsWith(other: Occurrence, toleranceMinutes?: number) {
        const range = this.toDateRange();
        const otherRange = other.toDateRange();
        return (
            areOverlapping(range, otherRange) &&
            (!toleranceMinutes || getOverlappingDuration(range, otherRange) >= toleranceMinutes * 60 * 1000)
        );
    }
    toDateRange() {
        return ({
            startTime: this.start_time,
            endTime: this.end_time
        });
    }
    finalPrice() {
        return AppointmentBase.getFinalPriceFromData(this);
    }
}

export { Occurrence };
export type { AppointmentOrOccurrence };