import { Box } from "@mui/material";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Service } from "@marathon/client-side/entities/Service";
import useStyles from "./styles";

interface ServiceOptionListProp {
    pet: Pet,
    services: Service[],
    selectedServiceId?: string,
    handleServiceChange: (petId: string, serviceId: string) => void,
    disabled?: boolean,
    isFromGroomerSide?: boolean
}

export default function ServiceOptionList({ services, pet, selectedServiceId, handleServiceChange, disabled = false, isFromGroomerSide = false }: ServiceOptionListProp) {
    return (
        <Box>
            {pet.services
                .sort((a, b) => b.price - a.price)
                .map((petService, idx) => {
                    const service = services.find(s => s.id === petService.id);
                    if (!service)
                        throw new Error(`Service ${petService.id} could not be found`);

                    return (
                        <ServiceOption
                            key={idx}
                            isMostPopular={idx === 0}
                            isLastOptions={idx === 2}
                            pet={pet}
                            service={service}
                            isChecked={selectedServiceId === service.id}
                            price={petService.price}
                            handleServiceChange={handleServiceChange}
                            disabled={disabled}
                            isFromGroomerSide={isFromGroomerSide}
                        />
                    );
                })}
        </Box>
    );
}

interface ServiceOptionProp {
    pet: Pet,
    service: Service,
    isMostPopular: boolean,
    isLastOptions: boolean,
    price: number,
    isChecked: boolean,
    handleServiceChange: (petId: string, serviceId: string) => void,
    disabled?: boolean,
    isFromGroomerSide: boolean
}

const ServiceOption = ({ pet, service, price, isChecked, handleServiceChange, isMostPopular, disabled = false, isFromGroomerSide }: ServiceOptionProp) => {
    const classes = useStyles();
    return (
        <Box display="flex" alignItems="center" className={`pricing-row-service-option${isFromGroomerSide ? "-groomer" : ""}`}>
            <Box>
                <label className="radio">
                    <input type="radio" value={service.id}
                        onChange={_ => handleServiceChange(pet.id, service.id)}
                        checked={isChecked}
                        disabled={disabled} />
                    <span className={`${isFromGroomerSide ? "radio-groomer-check" : ""} check`}></span>
                </label>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                lineHeight="1rem"
                alignItems="flex-start"
                className={isChecked
                    ? "multiple-services-selection"
                    : "multiple-services-selection-unchecked"}
                onClick={() => handleServiceChange(pet.id, service.id)}>
                <Box alignItems="center" className={`${classes.serviceDescriptionText} ${isChecked && classes.selectedDescriptionText} ${isFromGroomerSide && classes.serviceDescriptionTextGroomerSide}`}>
                    {service.customers_description || service.description}{(isMostPopular && !isFromGroomerSide) && <span className={classes.mostPopular}>POPULAR</span>}
                </Box>
            </Box>
            <Box className={`${classes.servicePrice} ${isChecked && classes.selectedServicePrice}  ${isFromGroomerSide && classes.servicePriceGroomer}`}>
                {`$${price}`}
            </Box>
        </Box>
    );
};