import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Grid, Radio, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { createFilterOptions, FilterOptionsState } from "@mui/material/useAutocomplete";
import { CustomTooltip } from "./CustomTooltip";
import { Breed } from "@marathon/client-side/entities/Breed";
import { PetInput } from "@marathon/client-side/entities/PetInput";
import { assertFloat } from "@marathon/web/helpers/numberHelper";
import { PetAgeSelect } from "@marathon/web/components/PetAgeSelect";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";
import { FieldsetWrapper } from "./shared/CommonWrappers";

const defaultFilterOptions = createFilterOptions<Breed>();

const filterOptions = (options: Breed[], state: FilterOptionsState<Breed>) => {
  return defaultFilterOptions(options, state);
};

interface PetFormProps {
  pet: PetInput,
  handleChange: (x: PetInput) => void,
  breeds: Breed[],
  showName: boolean,
  handleBlurName?: () => void
}

export const PetForm = ({ pet, handleChange, breeds, showName = true, handleBlurName }: PetFormProps) => {
  const classes = useStyles();
  const isMobile = isMobileViewport();
  return (
    <div>
      <FieldsetWrapper>
        <Grid container spacing={isMobile ? 1.25 : 2}>
          {showName &&
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="outlined"
                color="primary"
                size="medium"
                className={classes.textFieldWithoutMargin}
                label="Name"
                value={pet?.name ?? ""}
                onChange={v => handleChange({ name: v.target.value })}
                onBlur={handleBlurName}
              />
            </Grid>}
          <Grid item xs={12} sm={showName ? 6 : 12}>
            <CustomTooltip disableHoverListener title="If you are not sure, use your best guess!" arrow placement="top" enterTouchDelay={0}>
              <Autocomplete<Breed>
                filterOptions={filterOptions}
                options={breeds}
                value={breeds.find(x => x.id === pet?.breed_id) ?? null}
                onChange={(_, newValue) =>
                  handleChange({
                    breed_id: newValue ? newValue.id : ""
                  })
                }
                getOptionLabel={breed => breed?.name ?? ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                classes={{
                  listbox: classes.listbox,
                  option: classes.option,
                  popupIndicator: classes.popupIndicator,

                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    className={classes.textFieldWithoutMargin}
                    fullWidth
                    label="Breed"
                    variant="outlined"
                  />)}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={6}>
            <PetAgeSelect isAdmin={false} age={pet.age} onChange={age => handleChange({ age })} size="medium" />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              className={classes.textFieldWithoutMargin}
              autoComplete="off"
              variant="outlined"
              color="primary"
              size="medium"
              label="Weight (lb)"
              fullWidth
              required
              type="number"
              inputProps={{ min: 0, pattern: "[0-9]*" }}
              value={(pet?.weight && pet.weight > 0) ? pet.weight : ""}
              onChange={e => {
                handleChange({ weight: Math.round(assertFloat(e.target.value)) ?? 0 });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="M"
              checked={pet.gender === "M"}
              onChange={_ => handleChange({ gender: "M" })}
              control={<Radio />}
              label="Boy"
              aria-required
              classes={{
                root: classes.radioButton
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="F"
              checked={pet.gender === "F"}
              onChange={_ => handleChange({ gender: "F" })}
              control={<Radio />}
              label="Girl"
              classes={{
                root: classes.radioButton
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              className={classes.textFieldWithoutMargin}
              variant="outlined"
              color="primary"
              size="medium"
              multiline
              fullWidth
              rows={2}
              InputLabelProps={{
                shrink: false,
              }}
              placeholder={`Tell us about ${pet?.name ?? null}'s medical history, grooming history, preferences, and sensitivities`}
              value={pet?.notes ?? ""}
              onChange={v => handleChange({ notes: v.target.value })}
            />
          </Grid>
        </Grid>
      </FieldsetWrapper>
    </div>
  );
};

export const validateForm = (pet: PetInput, setFormError?: React.Dispatch<React.SetStateAction<string>>) => {
  let formError = "";

  if (!pet.name) {
    formError = "Name is required";
  }
  else if (!pet.breed_id) {
    formError = "Breed is required";
  }
  else if (!pet.age) {
    formError = "Age is required";
  }
  else if (!pet.weight) {
    formError = "Weight is required";
  }
  else if (!pet.gender) {
    formError = "Gender is required";
  }

  if (setFormError) {
    setFormError(formError);
  }

  return formError === "";
};