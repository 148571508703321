import { Box } from "@mui/material";
import { Discount } from "@marathon/client-side/entities/Discount";

interface Props {
    discount: Discount,
    discountLabel: string
}

export const DiscountPriceRow = ({ discount, discountLabel }: Props) => {
    return (
        <Box className="pricing-row-container">
            <Box className="pricing-row-label">
                {discountLabel}
            </Box>
            <Box className="pricing-row-amount">{`-$${discount.value}`}</Box>
        </Box>
    );
};