import { DateTime } from "luxon";

enum CustomerLogActivities {
    conversationOpened = "Conversation opened",
    conversationClosed = "Conversation closed",
    conversationSnoozed = "Conversation snoozed",
    conversationUnsnoozed = "Conversation unsnoozed",
    userAssigned = "Assigned to user",
    userUnassigned = "User unassigned",
    teamAssigned = "Assigned to team",
    teamUnassigned = "Team unassigned",
    chatbotTurnedOn = "Chatbot turned on",
    chatbotTurnedOff = "Chatbot turned off",
}

interface CustomerLogData {
    description: string,
    date: Date,
    user?: string
}

interface CustomerLogBase extends CustomerLogData {
    id: string
    customerId: string
}

class CustomerLogBase {
    constructor(id: string, customerId: string, data: CustomerLogData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    toData(): CustomerLogData {
        const { id, customerId, ...data } = this;
        return data;
    }
    toText() {
        return (
            this.description +
            (this.user ? ` by ${this.user}` : "") +
            ` on ${DateTime.fromJSDate(this.date).toFormat("MMMM d")} at ${DateTime.fromJSDate(this.date).toFormat("h:mm a")}`
        );
    }
}

export { CustomerLogBase, CustomerLogActivities };
export type { CustomerLogData };