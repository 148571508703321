import { DiscountType } from "@marathon/common/entities/Discount";
import { Discount } from "@marathon/client-side/entities/Discount";
import { Box, Typography } from "@mui/material";

export const DiscountCardFirstOccurrence = ({ discounts }: { discounts: Discount[] }) => {
    const relevantDiscounts = discounts.filter(x =>
        x.type === DiscountType.firstOccurrence ||
        x.type === DiscountType.mobileServiceFee
    );
    return (
        <Box
            width={{ xs: "100%" }}
            height={"36px"}
            bgcolor={"#FBEDFF"}
            borderRadius={"10px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"10px 0px"}
        >
            <Typography
                style={{ fontSize: 12 }}
                textAlign="center"
                fontFamily="Plain Bold"
                color="#CB29F3"
                fontWeight="bold">
                Save up to ${relevantDiscounts.reduce((prev, current) => prev + current.value, 0)} by setting up recurring at checkout
            </Typography>
        </Box>
    );
};