interface QueryFilter {
    field: string,
    operator: FilterOperator,
    value: unknown
}

enum FilterOperator {
    in = "in",
    greaterThanOrEqual = ">=",
    lessThanOrEqual = "<=",
    greaterThan = ">",
    lessThan = "<",
    equal = "=="
}

export function getFilter(field: string, operator: FilterOperator, value: unknown): QueryFilter {
    return { field, operator, value };
}

export { FilterOperator };
export type { QueryFilter };