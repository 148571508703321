import { Box, Chip } from "@mui/material";
import { SecondaryAddress } from "@marathon/client-side/entities/SecondaryAddress";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { getAddressLabel } from "@marathon/common/addressHelper";
import { Hub } from "@marathon/client-side/entities/Hub";
import { AddressSelectorOriginApp } from "./AddressSelector";

interface AddressOptionProp {
    address: SecondaryAddress | CustomerAddressData,
    isChecked: boolean,
    handleSelectedAddressChange: (address: SecondaryAddress | CustomerAddressData) => void,
    originApp: AddressSelectorOriginApp,
    hubs: Hub[]
}

export const AddressOption = ({ address, isChecked, handleSelectedAddressChange, hubs, originApp }: AddressOptionProp) => {
    const addressHub = hubs.find(hub => hub.id === address.drive_time?.hub_id);
    const isFromGroomerSide = originApp === AddressSelectorOriginApp.Groomer;
    const isFromCustomersSide = originApp === AddressSelectorOriginApp.Customer;
    return (
        <Box display="flex" alignItems="center" className={`address-selector-option${isFromGroomerSide ? "-groomer" : ""}`}>
            <Box>
                <label className="radio">
                    <input type="radio" value={address.address1}
                        onChange={_ => handleSelectedAddressChange(address)}
                        checked={isChecked}
                    />
                    <span className={`${isFromGroomerSide ? "radio-groomer-check" : ""} check`}></span>
                </label>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                lineHeight="1rem"
                alignItems="flex-start"
                className={isChecked
                    ? "multiple-address-selection"
                    : "multiple-address-selection-unchecked"}
                onClick={() => handleSelectedAddressChange(address)}>
                <Box alignItems="center" className={`address-description-text ${isChecked && "selected-address-description-text"}`}>
                    {getAddressLabel(address)}
                    {!isFromCustomersSide && addressHub?.acronym &&
                        <Chip
                            style={{
                                fontFamily: "Inter Regular",
                                color: "#827d7d",
                                backgroundColor: "#bbbbbb5c",
                                lineHeight: "12px",
                                fontWeight: "500",
                                fontSize: "12px",
                                height: "20px",
                                marginLeft: "5px"
                            }}
                            label={addressHub.acronym}
                        />}
                </Box>
            </Box>
        </Box>
    );
};

