import { dailyAnnouncementsCollectionGroup, groomerDailyAnnouncementsCollection, getNonEmptyData, getParentId } from "@marathon/client-side/database";
import { getDocs, query, where, DocumentSnapshot, addDoc } from "firebase/firestore";
import { DailyAnnouncementBase, DailyAnnouncementData } from "@marathon/common/entities/DailyAnnouncement";
import LocalDate from "@marathon/common/LocalDate";
import { TimeZone } from "@marathon/common/timeZoneHelper";

const mapEntity = function (snapshot: DocumentSnapshot<DailyAnnouncementData>) {
    return new DailyAnnouncement(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class DailyAnnouncement extends DailyAnnouncementBase {
    static async create(groomerId: string, data: DailyAnnouncementData) {
        const collection = groomerDailyAnnouncementsCollection(groomerId);
        return await addDoc(collection, data);
    }
    static async search(sentDate: LocalDate, groomerId?: string) {
        const collection = groomerId
            ? groomerDailyAnnouncementsCollection(groomerId)
            : dailyAnnouncementsCollectionGroup();
        const customQuery = query(collection,
            where("sent_date", ">=", sentDate.toDayStart()),
            where("sent_date", "<=", sentDate.toDayEnd()),
            where("time_zone", "==", sentDate.contextTimeZone)
        );
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchForAllHubs(fromDate: LocalDate) {
        //HACK: Since the from field is the start of the day, we always need to filter by timezone
        const dailyAnnouncements = await Promise.all(
            Object.values(TimeZone).map(async timeZone => this.search(LocalDate.fromLocalDate(fromDate, timeZone)))
        );
        return dailyAnnouncements.flat();
    }
}