import { DateTime } from "luxon";
import LocalDate from "./LocalDate";

enum TimeZone {
    PacificTime = "America/Los_Angeles",
    CentralTime = "America/Chicago",
    ArizonaTime = "America/Phoenix",
    EasternTime = "America/New_York",
    //TODO: Take into account switch-based methods when adding new timezones.
}

//HACK: We use this instead of the ZZZZ luxon format to avoid changes when crossing DST boundaries.
export function getTimeZoneBaseAcronym(timeZone: TimeZone) {
    switch (timeZone) {
        case TimeZone.EasternTime:
            return "ET";
        case TimeZone.CentralTime:
            return "CT";
        case TimeZone.ArizonaTime:
            return "AT";
        case TimeZone.PacificTime:
            return "PT";
        default:
            throw new Error(`Unknown timezone: ${timeZone}`);
    }
}

export function getTimeZoneCalendarSorting(timeZone: TimeZone) {
    switch (timeZone) {
        case TimeZone.EasternTime:
            return 1;
        case TimeZone.CentralTime:
            return 2;
        case TimeZone.ArizonaTime:
            return 3;
        case TimeZone.PacificTime:
            return 4;
        default:
            throw new Error(`Unknown timezone: ${timeZone}`);
    }
}

export function getDateForcingTimeZone(date: Date, timeZone: TimeZone) {
    return DateTime
        .fromJSDate(date)
        .setZone(timeZone, { keepLocalTime: true })
        .toJSDate();
}

export function getDateInLocalTimeZone(date: Date, timeZone: TimeZone) {
    return DateTime
        .fromJSDate(date)
        .setZone(timeZone)
        .setZone(DateTime.local().zone.name, { keepLocalTime: true })
        .toJSDate();
}

export function getDayStartForLocalTimeZone(date: Date) {
    return DateTime.fromJSDate(date).startOf("day").toJSDate();
}

export function getDayStartForTimeZone(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date).setZone(timeZone).startOf("day").toJSDate();
}

export function getDateWithMergedTime(date: Date, timeDate: Date, timeZone: TimeZone) {
    const time = DateTime.fromJSDate(timeDate, { zone: timeZone });
    return DateTime
        .fromJSDate(date, { zone: timeZone })
        .set({ hour: time.hour, minute: time.minute })
        .toJSDate();
}

export function getHoursOfDate(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).hour;
}

export function getDayName(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("cccc");
}

export function getLocalizedDateTime(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone });
}

export function isToday(date: Date, timeZone: TimeZone) {
    return LocalDate.forContextTimeZone(new Date(), timeZone).includesDate(date);
}

export function areSameDay(date: Date, other: Date, timeZone: TimeZone) {
    return LocalDate.forContextTimeZone(date, timeZone).includesDate(other);
}

export const isLocalTimeZoneEqualTo = (timeZone: string) => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone === timeZone;
};

export function getDatePlusDays(date: Date, days: number, timeZone: TimeZone) {
    //HACK: We need to specify the timezone to avoid changing the time when crossing DST boundaries.
    return DateTime.fromJSDate(date, { zone: timeZone }).plus({ days }).toJSDate();
}

export { TimeZone };