import blog from "images/blog.jpg";
import thanks from "images/thanks.gif";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { AppointmentScheduledCard } from "./AppointmentScheduledCard";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { Appointment } from "@marathon/client-side/entities/Appointment";
import { Pet } from "@marathon/client-side/entities/Pet";
import { mixPanelEvent, OnlineBookingFlowType } from "@marathon/common/constants";
import { TrackEventCallback } from "./tracker/useTracker";
import { Recurrence } from "@marathon/client-side/entities/Recurrence";
import { TitleWrapper, ContentWrapper } from "./CommonWrappers";
import { Hub } from "@marathon/client-side/entities/Hub";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { CreditCard } from "@marathon/client-side/entities/CreditCard";

interface ThankYouProps {
    appointment: Appointment | Recurrence | null,
    suggestion: BookingSuggestion | null
    pets: Pet[],
    trackEvent?: TrackEventCallback,
    flowType: OnlineBookingFlowType,
    addNewPaymentMethod?: () => void,
    hubs: Hub[],
    cards?: CreditCard[],
    isFromBookingSuggestion?: boolean,
    goToMyAccount?: () => void
}

const BASE_NEW_CUSTOMER_SUBTITLE = "We'll be reaching out shortly to confirm a few final details to make for a successful spaw day. Please text us a photo of %petNames to %hubNumber to help us get started so we can add it to their profile! Chat soon!";

export default function ThankYou({ appointment, suggestion, pets, trackEvent, flowType, hubs, cards, isFromBookingSuggestion, addNewPaymentMethod, goToMyAccount }: ThankYouProps) {
    const classes = useStyles();

    if (!suggestion || !appointment)
        return null;

    const handleBlurCalendarCallback = () => {
        if (trackEvent) {
            trackEvent({
                eventName: flowType === OnlineBookingFlowType.newCustomer ? mixPanelEvent.ob_new_finalStep_addToCal : mixPanelEvent.ob_return_finalStep_addToCal,
                properties: { new_user: flowType === OnlineBookingFlowType.newCustomer }
            });
        }
    };

    const handleClickBlogLink = () => {
        if (trackEvent) {
            trackEvent({
                eventName: flowType === OnlineBookingFlowType.newCustomer ? mixPanelEvent.ob_new_finalStep_blogClick : mixPanelEvent.ob_return_finalStep_blogClick,
                properties: { new_user: flowType === OnlineBookingFlowType.newCustomer }
            });
        }
    };

    const getSubtitle = (bookedAppointment: Appointment | Recurrence) => {
        const selectedPets = pets.filter(x => bookedAppointment.selected_pets.map(y => y.petId).includes(x.id));

        const hub = hubs.find(x => x.id === bookedAppointment.groomer.hub_id);
        if (!hub)
            throw new Error(`Hub not found: ${bookedAppointment.groomer.hub_id}`);

        const petNames = Pet.getPetNames(selectedPets).replace("and", "&");

        return BASE_NEW_CUSTOMER_SUBTITLE.replace("%petNames", petNames).replace("%hubNumber", hub.getFormattedPhoneNumber());
    };

    const isRecurrence = appointment instanceof Recurrence;
    const isNewCustomer = flowType === OnlineBookingFlowType.newCustomer;
    const shouldShowAddCardButton = cards && cards.length === 0 && !isFromBookingSuggestion;

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                mt={{ xs: 5.5, sm: 5.5 }}
                title="Thank you, we are all set!"
                subtitle={isNewCustomer ? getSubtitle(appointment) : "Can't wait to see you."}
            />
            <ContentWrapper>
                <div style={{ marginTop: 30 }}>
                    <img src={thanks} alt="barkbus-thank-you-page" width="332" height="328" />
                </div>
                {shouldShowAddCardButton &&
                    <div style={{ marginTop: 40 }}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={addNewPaymentMethod}
                            disableElevation>
                            Save Time & Add Card Now
                        </Button>
                    </div>}
                <div style={{ marginTop: 10 }}>
                    <Button
                        variant="outlined"
                        className={classes.buttonSecondary}
                        onClick={goToMyAccount}
                        disableElevation>
                        My Account
                    </Button>
                </div>
                <div style={{ marginTop: 30 }}>
                    {isRecurrence && <div style={{ fontSize: 16, lineHeight: 1 }}>Your next "Spaw" Day is confirmed for</div>}
                    <AppointmentScheduledCard
                        suggestion={suggestion}
                        appointment={appointment}
                        onBlurAddToCalendarCallback={handleBlurCalendarCallback}
                        pets={pets}
                    />
                    {isRecurrence && appointment.frequency && <div style={{ fontSize: 16, lineHeight: 1.5 }}>Hurray! You have reserved your preferred dates, time and stylist every {appointment.frequency.interval} {appointment.frequency.interval > 1 ? "weeks" : "week"}! Our concierge will contact you shortly to confirm the specifics.</div>}
                </div>
                <div className="thank-you-page">
                    <div>
                        <p style={{ fontWeight: 700 }}>A few recommendations to get your <br /> sweet dog ready for a grooming session:</p>
                    </div>
                    <div>
                        <p style={{ fontWeight: 700, marginTop: 55, marginBottom: 20 }}>Exercise:</p>
                        <p>To allow for a calm and relaxing grooming experience, we suggest<br /> exercise for your pup in advance of the appointment. Please try a<br /> 60 minute walk or a game of fetch!</p>
                    </div>
                    <div>
                        <p style={{ fontWeight: 700, marginTop: 55, marginBottom: 20 }}>Meet & Greet: </p>
                        <p>Our Pet Stylist will text you with an ETA and then again upon arrival. Please bring your pup to meet<br /> us outside of the home, preferably halfway down the driveway (or a<br /> similar area). Ideally, we meet away from the front door threshold as a<br /> neutral setting often reduces anxiety.</p>
                    </div>
                    <div>
                        <p style={{ fontWeight: 700, marginTop: 55, marginBottom: 20 }}>Leash Safety</p>
                        <p>Be sure to keep your pup on a leash until the Pet Stylist is able to<br /> securely transfer to the styling lead.</p>
                    </div>
                    <div>
                        <img src={blog} alt="barkbus-blog" width="400" style={{ marginTop: 48 }} />
                    </div>
                </div>
                <a
                    style={{ textDecoration: "underline", cursor: "pointer", fontSize: 22, lineHeight: "55px", color: "#2a58b3" }}
                    onBlurCapture={handleClickBlogLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.barkbus.com/blog"
                >
                    In the meantime, check out our <br /><p className="link">blog, The Barkbus Ruff Draft</p>
                </a>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}
