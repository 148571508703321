import loadGoogleMapsApi from "@marathon/web/loadGoogleMapsApi";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { isWithinServiceAreaForHub } from "@marathon/common/mapHelper";
import { Hub } from "@marathon/client-side/entities/Hub";

const DISTANCE_MATRIX_STATUS_OK = "OK";

interface GoogleAddressValue {
  terms: { value: string }[]
  place_id?: string
}

async function getDriveTimes(origins: google.maps.Place[], destinations: google.maps.Place[]): Promise<{ duration: google.maps.Duration; }[]> {
  await loadGoogleMapsApi();
  return new Promise((resolve, reject) => {
    const googleService = new window.google.maps.DistanceMatrixService();
    googleService.getDistanceMatrix(
      {
        origins,
        destinations,
        travelMode: google.maps.TravelMode.DRIVING
      },
      response => {
        if (!response)
          throw new Error("Error calling googleService.getDistanceMatrix");
        const driveTimes = response.rows
          .map(x => x.elements[0])
          .filter(x => x.status === DISTANCE_MATRIX_STATUS_OK)
          .map(x => ({ duration: x.duration }));
        if (driveTimes.length !== response.rows.length) {
          reject(
            new Error(
              `Some distance matrix API comparisons failed for ${destinations
                .map(x => x.placeId)
                .join(",")}.`
            )
          );
        } else {
          driveTimes.sortByFieldAscending(x => x.duration.value);
          resolve(driveTimes);
        }
      }
    );
  });
}

export async function getDriveTimeFromSpecificHub(placeId: string, hub: Hub) {
  const driveTimes = await getDriveTimes(
    [{ placeId: hub.place_id }],
    [{ placeId }]
  );
  return {
    hub_id: hub.id,
    duration_text: driveTimes[0].duration.text,
    duration_value: driveTimes[0].duration.value
  };
}

async function getDriveTimeFromHub(coordinates: { lat: number, lng: number }, placeId: string, hubs: Hub[]) {
  const hub = hubs.find(x => isWithinServiceAreaForHub(coordinates, x));
  if (!hub)
    return null;

  return getDriveTimeFromSpecificHub(placeId, hub);
}

function getRawAddress(value: GoogleAddressValue) {
  return value.terms.slice(0, -1).map(x => x.value).join(" ");
}

export async function getLatAndLngFromZip(zip: string) {
  await loadGoogleMapsApi();
  const geocoder = new window.google.maps.Geocoder();
  return new Promise<{ lat: number, lng: number, bounds?: google.maps.LatLngBounds }>((resolve, reject) => {
    geocoder.geocode({ address: zip }, (results, status) => {
      if (status === "OK" && results) {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          bounds: results[0].geometry.bounds
        });
      } else {
        reject(new Error(`Geocode was not successful for the following reason: ${status}`));
      }
    });
  });
}

export async function getAddressByGoogleAutocomplete(selected: { value: GoogleAddressValue }, hubs: Hub[]): Promise<CustomerAddressData> {
  const address: CustomerAddressData = {
    address1: getRawAddress(selected.value),
    address2: "",
    city: "",
    state: "",
    country: "",
    place_id: selected.value.place_id
  };

  if (!selected.value.place_id) {
    return address;
  }
  else {
    const geocoderResults = await geocodeByPlaceId(selected.value.place_id);
    if (geocoderResults.length === 0)
      return address;

    const geocoderResult = geocoderResults[0];
    const geocodedAddress = getAddressFromGeocoderResult(geocoderResult);
    const coordinates = {
      lat: geocoderResult.geometry.location.lat(),
      lng: geocoderResult.geometry.location.lng()
    };

    return {
      ...geocodedAddress,
      ...coordinates,
      place_id: geocoderResult.place_id,
      drive_time: await getDriveTimeFromHub(coordinates, selected.value.place_id, hubs) ?? undefined
    };
  }
}

export async function calculateRoute(hubPlaceId: string, customerPlaceIds: string[]) {
  await loadGoogleMapsApi();
  const directionsService = new window.google.maps.DirectionsService();

  return (
    await directionsService
      .route({
        origin: { placeId: hubPlaceId },
        destination: { placeId: hubPlaceId },
        waypoints: customerPlaceIds.map(placeId => ({
          location: { placeId },
          stopover: true
        })),
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING,
      })
  );
}

export async function getGoogleAutocompleteOption(placeId: string) {
  await loadGoogleMapsApi();
  const results = await geocodeByPlaceId(placeId);
  if (results && results[0] && results[0].formatted_address) {
    return {
      label: results[0].formatted_address,
      value: placeId,
    };
  }
  return null;
}

//TODO: Solve the type incompatibility (between web and node SDKs) and unify with the getAddressFromTextInput function in packages/common/addressHelper.ts
function getAddressFromGeocoderResult(geocoderResult: google.maps.GeocoderResult) {
  const address: CustomerAddressData = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: ""
  };
  geocoderResult.address_components.forEach(x => {
    if (x.types.includes(AddressType.street_number))
      address.address1 = x.long_name;
    else if (x.types.includes(AddressType.route)) {
      address.address1 += ` ${x.long_name}`;
      address.street_name = x.short_name;
    }
    else if (x.types.includes(AddressType.locality))
      address.city = x.long_name;
    else if (x.types.includes(AddressType.administrative_area_level_1))
      address.state = x.short_name;
    else if (x.types.includes(AddressType.postal_code))
      address.zip = x.long_name;
    else if (x.types.includes(AddressType.country))
      address.country = x.short_name;
    else if (x.types.includes(AddressType.neighborhood))
      address.area = x.long_name;
  });
  return address;
}

enum AddressType {
  street_number = "street_number",
  route = "route",
  locality = "locality",
  administrative_area_level_1 = "administrative_area_level_1",
  postal_code = "postal_code",
  country = "country",
  neighborhood = "neighborhood"
}

export type { GoogleAddressValue };