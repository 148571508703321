import { getFirestore, CollectionReference, Query, collection, collectionGroup, DocumentData, DocumentSnapshot, query, documentId, where, getDocs } from "firebase/firestore";
import { AppointmentData } from "@marathon/common/entities/Appointment";
import { CustomerData } from "@marathon/common/entities/Customer";
import { CustomerNotificationData } from "@marathon/common/entities/CustomerNotification";
import { CustomerLogData } from "@marathon/common/entities/CustomerLog";
import { GroomerData } from "@marathon/common/entities/Groomer";
import { GroomerNotificationData } from "@marathon/common/entities/GroomerNotification";
import { DailyAnnouncementData } from "@marathon/common/entities/DailyAnnouncement";
import { HubData } from "@marathon/common/entities/Hub";
import { MessageData } from "@marathon/common/entities/Message";
import { PersonalEventData } from "@marathon/common/entities/PersonalEvent";
import { PetData } from "@marathon/common/entities/Pet";
import { RecurrenceData } from "@marathon/common/entities/Recurrence";
import { SecondaryContactData } from "@marathon/common/entities/SecondaryContact";
import { BreedData } from "@marathon/common/entities/Breed";
import { ServiceData } from "@marathon/common/entities/Service";
import { UserData } from "@marathon/common/entities/User";
import { TimestampConverter } from "./TimestampConverter";
import { DriveTimeData } from "@marathon/common/entities/DriveTime";
import { MessageTemplateData } from "@marathon/common/entities/MessageTemplate";
import { SegmentData } from "@marathon/common/entities/Segment";
import { GroomerExceptionData } from "@marathon/common/entities/GroomerException";
import { RevisionData } from "@marathon/common/entities/Revision";
import { PricingPresetData } from "@marathon/common/entities/PricingPreset";
import { DiscountData } from "@marathon/common/entities/Discount";
import { CreditData } from "@marathon/common/entities/Credit";
import { GPTActivityData } from "@marathon/common/entities/GPTActivity";
import { GPTPromptData } from "@marathon/common/entities/GPTPrompt";
import { ReferralActivityData } from "@marathon/common/entities/ReferralActivity";
import { GroomerLogData } from "@marathon/common/entities/GroomerLog";
import { AircallActivityData } from "@marathon/common/entities/AircallActivity";
import { BroadcastMessageActivityData } from "@marathon/common/entities/BroadcastMessageActivity";
import { SecondaryAddressData } from "@marathon/common/entities/SecondaryAddress";
import { CustomOfficeClosureData } from "@marathon/common/entities/CustomOfficeClosure";
import { FederalHolidayData } from "@marathon/common/entities/FederalHoliday";
import { CreditCardData } from "@marathon/common/entities/CreditCard";
import { RestrictedServiceGroupData } from "@marathon/common/entities/RestrictedServiceGroup";
import { VanData } from "@marathon/common/entities/Van";
import { VanExceptionData } from "@marathon/common/entities/VanException";
import { AutomatedMessageData } from "@marathon/common/entities/AutomatedMessage";
import { CompanyGoalData } from "@marathon/common/entities/CompanyGoal";
import { DiscountCodeData } from "@marathon/common/entities/DiscountCode";
import { MAX_IN_PARAMETERS_LENGTH } from "@marathon/common/constants";

const firestore = getFirestore();

const createCollection = <T extends DocumentData = DocumentData>(path: string) => {
    const result = collection(firestore, path) as CollectionReference<T>;
    return result.withConverter<T, DocumentData>(new TimestampConverter<T>());
};

const createCollectionGroup = <T extends DocumentData = DocumentData>(collectionId: string) => {
    const result = collectionGroup(firestore, collectionId) as Query<T>;
    return result.withConverter<T, DocumentData>(new TimestampConverter<T>());
};

export const appointmentsCollection = createCollection<AppointmentData>("appointments");
export const recurrencesCollection = createCollection<RecurrenceData>("recurrences");
export const customersCollection = createCollection<CustomerData>("customers");
export const personalEventsCollection = createCollection<PersonalEventData>("personal_events");
export const groomersCollection = createCollection<GroomerData>("groomers");
export const groomerExceptionsCollection = createCollection<GroomerExceptionData>("groomer_exceptions");
export const hubsCollection = createCollection<HubData>("hubs");
export const breedsCollection = createCollection<BreedData>("breeds");
export const servicesCollection = createCollection<ServiceData>("services");
export const usersCollection = createCollection<UserData>("users");
export const configurationsCollection = createCollection<DocumentData>("configuration");
export const insightsCollection = createCollection<DocumentData>("insights");
export const messageTemplatesCollection = createCollection<MessageTemplateData>("message_templates");
export const segmentsCollection = createCollection<SegmentData>("segments");
export const pricingPresetsCollection = createCollection<PricingPresetData>("pricing_presets");
export const discountsCollection = createCollection<DiscountData>("discounts");
export const discountCodesCollection = createCollection<DiscountCodeData>("discount_codes");
export const gptActivitiesCollection = createCollection<GPTActivityData>("gpt_activities");
export const gptPromptsCollection = createCollection<GPTPromptData>("gpt_prompts");
export const referralActivitiesCollection = createCollection<ReferralActivityData>("referral_activities");
export const aircallActivitiesCollection = createCollection<AircallActivityData>("aircall_activities");
export const broadcastMessageActivitiesCollection = createCollection<BroadcastMessageActivityData>("broadcast_message_activities");
export const federalHolidayCollection = createCollection<FederalHolidayData>("federal_holidays");
export const customOfficeClosuresCollection = createCollection<CustomOfficeClosureData>("custom_office_closures");
export const restrictedServiceGroupsCollection = createCollection<RestrictedServiceGroupData>("restricted_service_groups");
export const automatedMessagesCollection = createCollection<AutomatedMessageData>("automated_messages");
export const vansCollection = createCollection<VanData>("vans");
export const vanExceptionsCollection = createCollection<VanExceptionData>("van_exceptions");
export const companyGoalsCollection = createCollection<CompanyGoalData>("company_goals");
export const customerMessagesCollection = (customerId: string) => createCollection<MessageData>(`customers/${customerId}/messages`);
export const customerPetsCollection = (customerId: string) => createCollection<PetData>(`customers/${customerId}/pets`);
export const customerLogsCollection = (customerId: string) => createCollection<CustomerLogData>(`customers/${customerId}/logs`);
export const customerNotificationsCollection = (customerId: string) => createCollection<CustomerNotificationData>(`customers/${customerId}/notifications`);
export const customerCreditsCollection = (customerId: string) => createCollection<CreditData>(`customers/${customerId}/credits`);
export const customerCreditCardsCollection = (customerId: string) => createCollection<CreditCardData>(`customers/${customerId}/credit_cards`);
export const groomerNotificationsCollection = (groomerId: string) => createCollection<GroomerNotificationData>(`groomers/${groomerId}/notifications`);
export const groomerDailyAnnouncementsCollection = (groomerId: string) => createCollection<DailyAnnouncementData>(`groomers/${groomerId}/daily_announcements`);
export const groomerMessagesCollection = (groomerId: string) => createCollection<MessageData>(`groomers/${groomerId}/messages`);
export const groomerLogsCollection = (groomerId: string) => createCollection<GroomerLogData>(`groomers/${groomerId}/logs`);
export const customerSecondaryContactsCollection = (customerId: string) => createCollection<SecondaryContactData>(`customers/${customerId}/secondary_contacts`);
export const customerSecondaryAddressesCollection = (customerId: string) => createCollection<SecondaryAddressData>(`customers/${customerId}/secondary_addresses`);
export const recurrenceDriveTimesCollection = (recurrenceId: string) => createCollection<DriveTimeData>(`recurrences/${recurrenceId}/drive_times`);
export const appointmentRevisionsCollection = <T>(appointmentId: string) => createCollection<RevisionData<T>>(`appointments/${appointmentId}/revisions`);
export const recurrenceRevisionsCollection = <T>(recurrenceId: string) => createCollection<RevisionData<T>>(`recurrences/${recurrenceId}/revisions`);

export const messagesCollectionGroup = () => createCollectionGroup<MessageData>("messages");
export const petsCollectionGroup = () => createCollectionGroup<PetData>("pets");
export const dailyAnnouncementsCollectionGroup = () => createCollectionGroup<DailyAnnouncementData>("daily_announcements");
export const driveTimesCollectionGroup = () => createCollectionGroup<DriveTimeData>("drive_times");
export const secondaryAddressesCollectionGroup = () => createCollectionGroup<SecondaryAddressData>("secondary_addresses");

export function getNonEmptyData<T>(snapshot: DocumentSnapshot<T>): T {
    const documentData = snapshot.data();
    if (!documentData) throw new Error("Data should not be empty");
    return documentData;
}

export function getParentId(snapshot: DocumentSnapshot): string {
    if (!snapshot.ref.parent.parent) throw new Error(`Parent should not be null for ${snapshot.ref.path}`);
    return snapshot.ref.parent.parent.id;
}

export async function fetchEntitiesByIds<E, D>(collection: CollectionReference<D>, ids: string[], mapEntity: (snapshot: DocumentSnapshot<D>) => E): Promise<E[]> {
    const batches: Promise<E[]>[] = [];

    while (ids.length) {
        const batch = ids.splice(0, MAX_IN_PARAMETERS_LENGTH);

        const customQuery = query(
            collection,
            where(documentId(), "in", batch)
        );

        const batchPromise = getDocs(customQuery)
            .then(results => results.docs.map(result => mapEntity(result)))
            .catch(error => {
                console.error("Batch fetch error", error);
                throw error;
            });

        batches.push(batchPromise);
    }

    const batchResults = await Promise.all(batches);
    return batchResults.flat();
}