import { Fragment } from "react";
import { Box } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Customer } from "@marathon/client-side/entities/Customer";
import { Service } from "@marathon/client-side/entities/Service";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { PetServiceSelection } from "@marathon/web/components/onlineBooking/PetServiceSelection";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { errorMessages } from "@marathon/common/constants";
import { Credit } from "@marathon/client-side/entities/Credit";
import { logErrorAndShowTitleOnlyAlert } from "@marathon/web/helpers/errorHandlingHelper";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { useOnlineBookingContext } from "../OnlineBookingContext";
import { MobileServiceFeePriceRow } from "../MobileServiceFeePriceRow";
import { CreditPriceRow } from "../CreditPriceRow";
import LineDivider from "../shared/LineDivider";
import { TitleWrapper, ContentWrapper, FieldsetWrapper } from "../shared/CommonWrappers";
import { AddressSelector, AddressSelectorOriginApp } from "@marathon/web/components/address/AddressSelector";
import { SecondaryAddress } from "@marathon/client-side/entities/SecondaryAddress";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { Hub } from "@marathon/client-side/entities/Hub";
import { Discount } from "@marathon/client-side/entities/Discount";
import { DiscountCardFirstOccurrence } from "../shared/DiscountCardFirstOccurrence";
import { DiscountCardFirstTimeCustomer } from "../shared/DiscountCardFirstTimeCustomer";
import { DiscountCode } from "@marathon/client-side/entities/DiscountCode";
import { DiscountCard } from "../shared/DiscountCard";

interface OnlineBookingStep3PetSelectionProps {
    customer: Customer,
    pets: Pet[],
    services: Service[],
    onPetSelectionChange: (x: { petId: string, serviceId: string }[]) => void,
    isLoading: boolean,
    onNext: () => void,
    handleAddPetClick: () => void,
    handleChangeSelectedService: (petId: string, serviceId: string) => void,
    selectedServices: { petId: string, serviceId: string }[],
    mobileServiceFee: MobileServiceFeeConfiguration,
    credits?: Credit[],
    addresses: (SecondaryAddress | CustomerAddressData)[],
    selectedAddress?: SecondaryAddress | CustomerAddressData,
    handleSelectedAddressChange: (address: SecondaryAddress | CustomerAddressData) => void,
    hubs: Hub[],
    discounts: Discount[],
    discountCode?: DiscountCode,
    isTrackingStep?: boolean
}

export default function OnlineBookingStep3PetSelection({
    customer,
    pets,
    onPetSelectionChange,
    isLoading,
    onNext,
    handleAddPetClick,
    services,
    selectedServices,
    handleChangeSelectedService,
    mobileServiceFee,
    credits,
    addresses,
    selectedAddress,
    handleSelectedAddressChange,
    hubs,
    discounts,
    discountCode,
    isTrackingStep
}: OnlineBookingStep3PetSelectionProps) {
    const classes = useStyles();

    const handlePetSelection = (e: React.ChangeEvent<HTMLInputElement>, pet: Pet) => {
        try {
            const petService = e.target.checked
                ? selectedServices.concat({ petId: pet.id, serviceId: pet.getDefaultServiceId() })
                : selectedServices.filter(x => x.petId !== pet.id);
            onPetSelectionChange(petService);
        }
        catch (error) {
            logErrorAndShowTitleOnlyAlert(error, errorMessages.try_again_or_call_us);
        }
    };

    const { breeds } = useOnlineBookingContext();

    const showFirstOccurrenceDiscount = !discountCode && customer.qualifiesFor.firstOccurrenceDiscount(hubs);
    const showFirstTimeCustomerDiscount = !discountCode && customer.qualifiesFor.firstTimeCustomerDiscount(hubs);

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                title={`Welcome back ${customer.firstname.trim()}!`}
                subtitle="Who's ready for a spaw day?"
            />
            <ContentWrapper>
                {addresses.length > 1 &&
                    <Box className="selected-address-row-container">
                        <AddressSelector
                            addresses={addresses}
                            handleSelectedAddressChange={handleSelectedAddressChange}
                            selectedAddress={selectedAddress || undefined}
                            hubs={hubs}
                            originApp={AddressSelectorOriginApp.Customer}
                        />
                    </Box>}

                {discountCode && <DiscountCard discount={discountCode} />}
                {showFirstOccurrenceDiscount && <DiscountCardFirstOccurrence discounts={discounts} />}
                {showFirstTimeCustomerDiscount && <DiscountCardFirstTimeCustomer hubs={hubs} customer={customer} />}
                {pets.length > 0 &&
                    <FieldsetWrapper>
                        {pets.map((pet, idx) => (
                            <Fragment key={idx}>
                                <LineDivider
                                    marginTop={idx === 0 ? 0 : 20}
                                    marginBottom={20}
                                />
                                <PetServiceSelection
                                    key={idx}
                                    pet={pet}
                                    selectedServiceId={selectedServices.find(x => x.petId === pet.id)?.serviceId}
                                    services={services}
                                    breeds={breeds}
                                    handleChangeSelectedService={handleChangeSelectedService}
                                    checked={selectedServices.some(x => x.petId === pet.id)}
                                    onChange={e => handlePetSelection(e, pet)}
                                />
                            </Fragment>
                        ))}
                        {mobileServiceFee.is_enabled && !customer.skip_mobile_service_fee &&
                            <>
                                <LineDivider marginBottom={20} marginTop={20} />
                                <MobileServiceFeePriceRow fee={mobileServiceFee} customer={customer} />
                            </>}
                        {credits && credits.length > 0 &&
                            <>
                                <LineDivider marginBottom={20} marginTop={20} />
                                <CreditPriceRow credits={credits} />
                            </>}
                    </FieldsetWrapper>}
                {pets.length === 0 &&
                    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
                        <Box width={{ xs: "100%", sm: "400px" }} style={{ fontSize: 16, lineHeight: 1.5 }}>
                            Doesn't look like we have any dogs associated with your account.
                        </Box>
                    </Box>}
                <Button
                    onClick={handleAddPetClick}
                    variant={pets.length === 0 ? "contained" : "outlined"}
                    className={pets.length === 0 ? classes.button : classes.buttonSecondary}
                    style={{ marginTop: 35 }}
                >
                    {pets.length > 0 && <>+&nbsp;&nbsp;</>}
                    Add a dog
                </Button>
                {pets.length > 0 &&
                    <Button
                        showSpinner={isLoading || isTrackingStep}
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        onClick={onNext}
                        disabled={selectedServices.length === 0}
                        disableElevation>
                        Continue
                    </Button>}
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}