import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { formatDateForOnlineBooking } from "@marathon/common/timeFormatHelper";
import { AppointmentBase, AppointmentSelectedPet } from "@marathon/common/entities/Appointment";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { Customer } from "@marathon/client-side/entities/Customer";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Service } from "@marathon/client-side/entities/Service";
import { Groomer } from "@marathon/client-side/entities/Groomer";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Appointment } from "@marathon/client-side/entities/Appointment";
import { Button } from "@marathon/web/components/Button";
import { SecondaryAddress } from "@marathon/client-side/entities/SecondaryAddress";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";

interface AppointmentDetailProps {
    appointment: AppointmentOrOccurrence,
    addresses: (SecondaryAddress | CustomerAddressData)[],
    pets: Pet[],
    services: Service[],
    groomers: Groomer[],
    handleCancelAppointment: () => void,
    handleChangeAppointment: () => void
}

const AppointmentDetail = ({
    appointment,
    addresses,
    pets,
    services,
    groomers,
    handleCancelAppointment,
    handleChangeAppointment,
}: AppointmentDetailProps) => {
    const classes = useStyles();

    const groomer = groomers.find(g => g.id === appointment.groomer.id);
    if (!groomer)
        throw new Error(`Groomer ${appointment.groomer.id} not found`);

    const isCancelableOrModifiable = Appointment.isCancelable(appointment, true);

    const groupedEventPetServices = getGroupByEventPetServices(appointment.selected_pets, x => x.petId);

    const renderServices = (eventPetServices: AppointmentSelectedPet[]) => {
        return eventPetServices.map((eventPetService, idx) => {
            const service = services.find((x) => x.id === eventPetService.serviceId);
            if (!service) return null;

            return (
                <Box key={`service-${idx}`} className="appointment-detail-text">
                    {service.customers_description || service.name}: ${eventPetService.servicePrice}
                </Box>
            );
        });
    };

    const appointmentAddress = addresses.find(x =>
        x instanceof SecondaryAddress
            ? x.id === appointment.customer.secondary_address_id
            : !appointment.customer.secondary_address_id
    );

    if (!appointmentAddress) {
        Sentry.captureException(new Error(`Address not found for appointment ${appointment.id}`));
        return (
            <OnlineBookingContentWrapper>
                <TitleWrapper title="Upcoming Appointment" />
                <ContentWrapper>
                    <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px" }}>
                        <Typography fontFamily="Plain Bold" variant="h1" style={{ fontSize: "25px", margin: 0 }}>
                            {formatDateForOnlineBooking(appointment.start_time, appointment.time_zone)}
                        </Typography>
                        <Typography fontFamily="Plain Bold" mt={1}>
                            Address not found
                        </Typography>
                        <Typography mt={2}>
                            Please call or text us at <a className="phone-number" href={"tel:+18007429255"}>(800) 742-9255</a>
                        </Typography>
                    </Box>
                </ContentWrapper>
            </OnlineBookingContentWrapper>
        );
    }

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Upcoming Appointment" />
            <ContentWrapper>
                <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px" }}>
                    <Typography fontFamily="Plain Bold" variant="h1" style={{ fontSize: "25px", margin: 0 }}>
                        {formatDateForOnlineBooking(appointment.start_time, appointment.time_zone)}
                    </Typography>
                    <Typography fontFamily="Plain Bold" mt={1}>
                        {Customer.formattedAddress(appointmentAddress)}
                    </Typography>
                    <Typography fontFamily="Plain Bold" mt={1}>
                        {Appointment.getArrivalTime(appointment)} arrival
                    </Typography>
                    <Box mt={2} fontFamily="Plain">
                        <Box className="appointment-detail-text">Stylist: {groomer.obfuscatedName()}</Box>
                        {Array.from(groupedEventPetServices).map(([petId, eventPetServices], idx) => {
                            const pet = pets.find(x => x.id === petId);
                            if (!pet) {
                                return null;
                            }
                            else {
                                return (
                                    <Box key={`dog-${idx}`} className="appointment-detail-text">
                                        Dog: {pet.name}
                                        <Box>
                                            {renderServices(eventPetServices)}
                                        </Box>
                                    </Box>
                                );
                            }
                        })}
                        {appointment.extra_items && appointment.extra_items.length > 0 &&
                            <>
                                {appointment.extra_items.map((x, idx) => (
                                    <Box className="appointment-detail-text" key={idx}>{x.description}: ${x.price}</Box>
                                ))}
                            </>}
                        {appointment.discounts && appointment.discounts.length > 0 &&
                            <Box className="appointment-detail-text">
                                Discount: ${appointment.discounts.reduce((total, discount) => total + discount.value, 0)}
                            </Box>}
                        <Box className="appointment-detail-text">
                            Total: ${AppointmentBase.getFinalPriceFromData(appointment)}
                        </Box>
                        {appointment.frequency && !appointment.recurrence_id &&
                            <Box mt={2} fontFamily="Plain Medium">
                                Recurring: {appointment.frequency.interval} {appointment.frequency.interval === 1 ? "week" : "weeks"}
                            </Box>}
                    </Box>
                </Box>
                {isCancelableOrModifiable &&
                    <>
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.button}
                            disableElevation
                            onClick={() => handleChangeAppointment()}>
                            Change this appointment
                        </Button>
                        <Box style={{
                            fontSize: 14,
                            color: "#A3A3A3",
                            justifyContent: "center",
                            display: "flex",
                            marginTop: 24,
                            fontFamily: "Plain Medium"
                        }}>
                            <span
                                className={classes.auxiliaryButton}
                                onClick={() => handleCancelAppointment()}>
                                Cancel this appointment
                            </span>
                        </Box>
                    </>}
                {!isCancelableOrModifiable &&
                    <Box style={{ color: "#191919" }} mt={1}>
                        <Typography fontFamily="Plain Medium">This appointment is within the 24 hour cancellation window.</Typography>
                        <Typography fontFamily="Plain Medium" mt={2}>
                            <a href="tel:+18007429255" style={{ textDecoration: "underline", color: "inherit" }}>Call</a>
                            <span> or </span>
                            <a href="sms:+18007429255&body=Hi! I'd like to modify an appointment" style={{ textDecoration: "underline", color: "inherit" }}>text</a>
                            <span> us to modify this appointment.</span>
                        </Typography>
                    </Box>}
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default AppointmentDetail;

const getGroupByEventPetServices = <T, K>(array: T[], keyGetter: (item: T) => K): Map<K, T[]> => {
    const map = new Map<K, T[]>();

    array.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);

        if (collection) {
            collection.push(item);
        } else {
            map.set(key, [item]);
        }
    });

    return map;
};