import { ChangeEventHandler } from "react";
import { Box, FormControl, IconButton, MenuItem, Select } from "@mui/material";
import { Pet } from "@marathon/client-side/entities/Pet";
import { Service } from "@marathon/client-side/entities/Service";
import { Breed } from "@marathon/client-side/entities/Breed";
import ServiceOptionList from "./ServiceOptionList";
import useStyles from "./styles";
import { ServiceDescription } from "./ServiceDescription";
import "./PetServiceSelection.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PetServiceSelectionWrapper from "@marathon/client-side/components/onlineBooking/PetServiceSelectionWrapper";

interface Props {
    checked?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    pet: Pet,
    services: Service[],
    breeds: Breed[],
    selectedServiceId?: string,
    handleChangeSelectedService: (petId: string, serviceId: string) => void,
    selectedAddOnServiceIds?: string[],
    onAddNewAddOnService?: (petId: string, serviceId: string) => void,
    onChangeAddOnService?: (petId: string, oldServiceId: string, newServiceId: string) => void,
    onRemoveAddOnService?: (petId: string, serviceId: string) => void,
    showCheckbox?: boolean,
    isAddServiceAllowed?: boolean
}

export const PetServiceSelection = ({
    checked,
    onChange,
    pet,
    services,
    breeds,
    selectedServiceId,
    handleChangeSelectedService,
    selectedAddOnServiceIds = [],
    onAddNewAddOnService,
    onChangeAddOnService,
    onRemoveAddOnService,
    showCheckbox = true,
    isAddServiceAllowed = false
}: Props) => {
    const classes = useStyles();

    return (
        <PetServiceSelectionWrapper
            breeds={breeds}
            checked={checked}
            onAddNewAddOnService={onAddNewAddOnService}
            onChangeAddOnService={onChangeAddOnService}
            onRemoveAddOnService={onRemoveAddOnService}
            pet={pet}
            selectedAddOnServiceIds={selectedAddOnServiceIds}
            selectedServiceId={selectedServiceId}
            services={services}
            showCheckbox={showCheckbox}>
            {({ isUnchecked, selectedPetService, breed, selectedService, getDescription, handleChangeSelectedAddOnService, addOnServices, availableAddOnServices, handleRemoveSelectedAddOnService, canAddMoreServices, handleAddNewService }) => (
                <Box className="pricing-row-container">
                    <Box className={`pet-pricing-row-header ${isUnchecked ? "" : "has-content"}`}>
                        <Box display="flex" alignItems="center">
                            {showCheckbox &&
                                <label className="checkbox">
                                    <input type="checkbox" checked={checked} onChange={onChange} />
                                    <span className="check"></span>
                                </label>}
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="flex-start"
                                style={{ marginLeft: showCheckbox ? 27 : 0 }}>
                                <Box className="pricing-row-pet-name">{pet.name}</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="pricing-row-amount pet-pricing">
                        {pet.services.length === 1 && `$${pet.services[0].price}`}
                        {pet.services.length > 1 && isUnchecked &&
                            <>
                                {`$${pet.services[pet.services.length - 1].price}`} - {`$${pet.services[0].price}`}
                            </>}
                    </Box>
                    {pet.services.length === 1 && selectedPetService &&
                        <Box className="pet-pricing-row-content">
                            <Box className={classes.serviceCopyText}>
                                {breed.name} - {selectedService?.description}
                            </Box>
                            <Box className="pricing-row-service-description">
                                <ServiceDescription description={getDescription()} />
                            </Box>
                        </Box>}
                    {pet.services.length > 1 && selectedPetService &&
                        <Box className="pet-pricing-row-content multiple">
                            <Box className={classes.serviceCopyText}>
                                {breed.name} - Multiple Options
                            </Box>
                            <Box className={"pricing-row-service-description"}>
                                <ServiceDescription description={getDescription()} />
                            </Box>
                            <Box className={classes.serviceCopyText} style={{ marginTop: 10, marginBottom: 10 }}>Select one:</Box>
                            <ServiceOptionList
                                pet={pet}
                                disabled={!checked}
                                services={services}
                                selectedServiceId={selectedServiceId}
                                handleServiceChange={handleChangeSelectedService}
                            />
                        </Box>}
                    {isAddServiceAllowed &&
                        <Box>
                            {selectedAddOnServiceIds.map((serviceId, index) =>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                                    <FormControl style={{ width: "100%" }}>
                                        <Select
                                            key={`${pet.id}-${selectedAddOnServiceIds[index]}`}
                                            value={selectedAddOnServiceIds[index] || ""}
                                            onChange={(e) => handleChangeSelectedAddOnService(serviceId, e.target.value)}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            renderValue={(selectedServiceId) => {
                                                const service = addOnServices?.find(x => x.id === selectedServiceId);
                                                if (!selectedServiceId) {
                                                    return <span style={{ color: "#757575" }}>Service</span>;
                                                } else if (!service) {
                                                    return "Service not found";
                                                } else {
                                                    return service.formatOptionLabel(true);
                                                }
                                            }}>
                                            {availableAddOnServices?.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.formatOptionLabel(true)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Box>
                                        <IconButton aria-label="delete" onClick={() => handleRemoveSelectedAddOnService(index)} size="large">
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )}
                            {canAddMoreServices &&
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    mt={2}
                                    style={{ fontSize: "14px", color: "#b1b1b1", textDecoration: "underline", cursor: "pointer" }}
                                    onClick={handleAddNewService}>
                                    Add services
                                </Box>}
                        </Box>}
                </Box>
            )}
        </PetServiceSelectionWrapper>
    );
};