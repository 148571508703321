import { Customer } from "@marathon/client-side/entities/Customer";
import { Hub } from "@marathon/client-side/entities/Hub";
import { FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT } from "@marathon/common/DiscountUtils";
import { Box, Typography } from "@mui/material";

export const DiscountCardFirstTimeCustomer = ({ hubs, customer }: { hubs: Hub[], customer: Customer }) => {
    const customerHub = hubs.find(x => x.id === customer.address.drive_time?.hub_id);

    const firstTimeCustomerDiscount = customerHub?.first_time_customer_discount;
    if (!firstTimeCustomerDiscount?.is_enabled)
        throw new Error("First time customer discount should be present and enabled at this point");

    return (
        <Box
            width={{ xs: "100%" }}
            height={"62px"}
            bgcolor={"#FBEDFF"}
            borderRadius={"10px"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            margin={"10px 0px"}
        >
            <Typography
                style={{ fontSize: 12 }}
                textAlign="center"
                fontFamily="Plain Bold"
                color="#CB29F3"
                fontWeight="bold">
                First time customer discount -${firstTimeCustomerDiscount.value} applied at checkout
            </Typography>
            <Typography
                style={{ fontSize: 12 }}
                textAlign="center"
                fontFamily="Plain Bold"
                color="#CB29F3"
                fontWeight="bold">
                + Save another ${FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT} by setting up recurring at checkout
            </Typography>
        </Box>
    );
};