import { appointmentsCollection, getNonEmptyData } from "@marathon/client-side/database";
import {
  doc, getDoc, getDocs, addDoc, updateDoc, query, where, limit, orderBy,
  DocumentSnapshot, UpdateData, onSnapshot,
  deleteField
} from "firebase/firestore";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { MAX_IN_PARAMETERS_LENGTH, ONLINE_BOOKING_ORIGIN } from "@marathon/common/constants";
import { getDatePlusMinutes } from "@marathon/common/timeHelper";
import { TimeZone, getDatePlusDays } from "@marathon/common/timeZoneHelper";
import { AppointmentBase, AppointmentData, AppointmentSelectedPet, AppointmentStatus, PaymentStatus } from "@marathon/common/entities/Appointment";
import { AppointmentInput } from "./AppointmentInput";
import { Groomer } from "./Groomer";
import { Customer } from "./Customer";
import { Service } from "./Service";
import LocalDate from "@marathon/common/LocalDate";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { SecondaryAddress } from "./SecondaryAddress";
import { Recurrence } from "./Recurrence";
import { getFutureOccurrences } from "@marathon/common/rruleHelper";
import { isFirstHourService } from "@marathon/common/timeFormatHelper";
import { Message } from "./Message";
import { MessageParentType } from "@marathon/common/entities/Message";
import { Pet } from "./Pet";

const mapEntity = function (snapshot: DocumentSnapshot<AppointmentData>) {
  return new Appointment(snapshot.id, getNonEmptyData(snapshot));
};

const updateAudit = (data: UpdateData<AppointmentData>, user?: string) => {
  if (!data) return;
  data.updated_by = user || DeviceStorageCache.getCurrentUserName() || ONLINE_BOOKING_ORIGIN;
  data.updated_at = new Date();
};

const calendarEventStyles = {
  blueBackground: { backgroundColor: "#146bf5", color: "#edf3fe" },
  lightBlueBackground: { backgroundColor: "#bfdcff", color: "#1893ff" },
  lightBlueAccent: { backgroundColor: "#06beff", color: "#edf3fe" },
  lightGrayBackground: { backgroundColor: "#d4d4d4", color: "#000000" },
  yellowBackground: { backgroundColor: "#fff8ba", color: "#000000" },
  lightYellowBackground: { backgroundColor: "#fff6d4", color: "#000000" },
  blackBackground: { backgroundColor: "#000000", color: "#ffffff" },
  redBackground: { backgroundColor: "#be0000", color: "#ffffff" },
  linearGradientBackground: {
    background: `repeating-linear-gradient(
      -45deg,
      #3269ec,
      #3269ec 30px,
      #0a2bda 30px,
      #0a2bda 60px
    )`,
    color: "#ffffff",
  },
  defaultBackground: { color: "#000000", background: undefined, backgroundColor: undefined }
};

export class Appointment extends AppointmentBase {
  static async getById(id: string) {
    const reference = doc(appointmentsCollection, id);
    const snapshot = await getDoc(reference);
    if (!snapshot.exists()) {
      return null;
    }
    return mapEntity(snapshot);
  }
  static async create(data: AppointmentData) {
    if (await CallableFunctions.public.isNewCustomer(data.customer.id)) {
      data.is_new_customer = true;
    }
    if (await CallableFunctions.public.isFirstAppointment(data.groomer.id, data.start_time, data.time_zone) && isFirstHourService(data.start_time, data.time_zone)) {
      data.show_exact_time = true;
    }
    const newDocument = await addDoc(appointmentsCollection, data);
    return new Appointment(newDocument.id, data);
  }
  static async suggestNewTime(originalAppointmentId: string, suggestedAppointmentData: AppointmentData, expiration: number, message: string) {
    const expirationTime = new Date(new Date().getTime() + (expiration) * 60 * 1000);

    const data: AppointmentData = {
      ...suggestedAppointmentData,
      expiration_time: expirationTime,
      status: AppointmentStatus.pending,
      suggestion_for_appointment_id: originalAppointmentId
    };

    const suggestedAppointment = await Appointment.create(data);

    const toUpdate: Partial<AppointmentData> = {
      suggestion_appointment: {
        id: suggestedAppointment.id,
        status: AppointmentStatus.pending
      }
    };
    await Appointment.update(originalAppointmentId, toUpdate);

    await Message.createSms({
      collectionType: MessageParentType.Customers,
      parentId: suggestedAppointment.customer.id,
      content: message,
      phone: suggestedAppointment.customer.phone,
      suggestedAppointmentId: suggestedAppointment.id,
    });

    return suggestedAppointment;
  }
  static async update(id: string, data: UpdateData<AppointmentData>, user?: string) {
    updateAudit(data, user);
    const reference = doc(appointmentsCollection, id);
    await updateDoc(reference, data);
  }
  async acceptSuggestion() {
    if (!this.suggestion_for_appointment_id)
      throw new Error("Original appointment id not found");

    await Appointment.cancel(this.suggestion_for_appointment_id, Appointment.systemCancellationReasons.suggestionAccepted);

    await Appointment.update(this.id, {
      status: AppointmentStatus.scheduled,
      suggestion_for_appointment_id: deleteField()
    });
  }
  static async decline(id: string, user?: string) {
    const data: UpdateData<AppointmentData> = { status: AppointmentStatus.declined };
    await this.update(id, data, user);
  }
  async declineSuggestion() {
    if (!this.suggestion_for_appointment_id)
      throw new Error("Original appointment id not found");

    await Appointment.update(this.suggestion_for_appointment_id, { "suggestion_appointment.status": AppointmentStatus.declined });
    await Appointment.update(this.id, { status: AppointmentStatus.declined });
  }
  static async updateExpirationTime(id: string, minutes: number) {
    if (minutes < 0) return;
    const appointment = await this.getById(id);
    const currentExpirationTime = appointment?.expiration_time ?? new Date();
    const newDate = getDatePlusMinutes(currentExpirationTime, minutes);
    if (newDate < currentExpirationTime) return;
    const data: UpdateData<AppointmentData> = { expiration_time: newDate };
    await this.update(id, data);
    return newDate;
  }
  static async cancel(id: string, reason: string, notifyCustomer?: boolean) {
    const data: UpdateData<AppointmentData> = {
      status: AppointmentStatus.cancelled,
      cancellation: { reason, customer_notified: notifyCustomer || undefined }
    };
    updateAudit(data);
    const reference = doc(appointmentsCollection, id);
    await updateDoc(reference, data);
  }
  static getSelectedPetsFromInput(input: AppointmentInput, pets: Pet[]): AppointmentSelectedPet[] {
    return input.selectedPets.map(x => {
      if (!x.petId || !x.serviceId) {
        throw new Error("Invalid appointment pet input");
      }

      const pet = pets.find(p => p.id === x.petId);
      if (!pet) {
        throw new Error("Pet not found");
      }

      if (!pet.breed_id) {
        throw new Error("Pet breed is required at this point");
      }

      const selectedPet: AppointmentSelectedPet = {
        petId: x.petId,
        serviceId: x.serviceId,
        servicePrice: x.servicePrice,
        breedId: pet.breed_id,
        customServiceTime: x.customServiceTime,
        petWeight: pet.weight
      };

      return selectedPet;
    });
  }
  static getAppointmentToCreate(input: AppointmentInput, groomer: Groomer, customer: Customer, services: Service[], pets: Pet[], selectedSecondaryAddress?: SecondaryAddress, isRecreatingRecurrence?: true) {
    const duration = input.getDuration(services);
    const endTime = new Date(input.startTime.getTime() + duration * 60 * 1000);
    const currentUser = DeviceStorageCache.getCurrentUserName();
    const data = {
      customer: customer.getSummaryForAppointment(selectedSecondaryAddress, input.isFromSignup),
      notes: input.notes,
      notes_from_customer: input.notesFromCustomer,
      groomer: groomer.getSummaryForAppointment(),
      selected_pets: Appointment.getSelectedPetsFromInput(input, pets),
      extra_items: input.extraItems?.map(x => ({ ...x })),
      start_time: input.startTime,
      end_time: endTime,
      created_at: isRecreatingRecurrence ? input.createdAt : new Date(),
      status: input.isOnHold ? AppointmentStatus.pending : AppointmentStatus.scheduled,
      booked_by: isRecreatingRecurrence ? input.bookedBy : currentUser || ONLINE_BOOKING_ORIGIN,
      frequency: input.frequency,
      is_from_sms: input.isFromSms,
      attributed_to: input.attributedTo,
      is_from_online_booking: input.isFromOnlineBooking,
      time_zone: groomer.time_zone,
      discounts: input.rescheduleAppointment ? input.rescheduleAppointment.discounts : input.discounts,
      credits: input.rescheduleAppointment ? input.rescheduleAppointment.credits : input.credits,
      updated_at: isRecreatingRecurrence ? new Date() : undefined,
      updated_by: isRecreatingRecurrence ? currentUser : undefined,
      origin: input.origin,
    } as AppointmentData;

    if (input.isOnHold) {
      data.expiration_time = new Date(new Date().getTime() + (input.expiration) * 60 * 1000);
    }

    return data;
  }
  static getAppointmentToUpdate(input: AppointmentInput, groomer: Groomer, customer: Customer, services: Service[], pets: Pet[], selectedSecondaryAddress?: SecondaryAddress) {
    const duration = input.getDuration(services);
    const endTime = new Date(input.startTime.getTime() + duration * 60 * 1000);
    const data = {
      customer: customer.getSummaryForAppointment(selectedSecondaryAddress, input.isFromSignup),
      selected_pets: Appointment.getSelectedPetsFromInput(input, pets),
      groomer: groomer.getSummaryForAppointment(),
      start_time: input.startTime,
      end_time: endTime,
      status: input.status,
      payment_status: input.paymentStatus,
      notes: input.notes,
      notes_from_customer: input.notesFromCustomer,
      frequency: input.frequency || undefined,
      extra_items: input.extraItems,
      tip: input.collected && input.isPaid() ? input.collected - input.finalPrice() : undefined,
      service_end_time: !input.serviceEndTime && input.isCompleted() ? new Date() : input.serviceEndTime,
      attributed_to: input.attributedTo,
      time_zone: groomer.time_zone,
      grooming_notes: input.groomingNotes,
      discounts: input.discounts,
      origin: input.origin
    } as Partial<AppointmentData>;

    return data;
  }
  static async searchPrevious(customerId: string, startTime?: Date) {
    const date = startTime || new Date();
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("customer.id", "==", customerId),
        where("start_time", "<", date),
        where("status", "==", AppointmentStatus.completed),
        orderBy("start_time", "desc"),
        limit(10)
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchFuture(customerId: string) {
    const appointments = await Appointment.searchUpcomingByCustomer(customerId, new Date);
    const recurrences = await Recurrence.searchForCustomer(customerId);
    const futureOccurrences = recurrences.map(x => getFutureOccurrences(x, 1)).flat();
    const allFutureAppointments = [...appointments, ...futureOccurrences].sortByFieldAscending(x => x.start_time);
    return allFutureAppointments;
  }
  static async searchForDayAndTimeZone(fromDate: LocalDate, toDate: LocalDate, timeZone: TimeZone) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("start_time", ">=", fromDate.toDayStart()),
        where("start_time", "<=", toDate.toDayEnd()),
        where("time_zone", "==", timeZone)
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchForDayAndGroomer(fromDate: LocalDate, toDate: LocalDate, groomerId: string) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("start_time", ">=", fromDate.toDayStart()),
        where("start_time", "<=", toDate.toDayEnd()),
        where("groomer.id", "==", groomerId),
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchForDayAndHub(fromDate: LocalDate, toDate: LocalDate, hubId: string) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("start_time", ">=", fromDate.toDayStart()),
        where("start_time", "<=", toDate.toDayEnd()),
        where("groomer.hub_id", "==", hubId),
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchForCustomer(customerId: string) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("customer.id", "==", customerId),
        orderBy("start_time", "desc"),
        limit(50)
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchForGroomer(groomerId: string) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("groomer.id", "==", groomerId),
        orderBy("start_time", "desc"),
        limit(50)
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchFutureExceptions() {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("start_time", ">", new Date()),
      )
    );
    const appointments: Appointment[] = [];
    snapshot.forEach(doc => {
      const appointment = mapEntity(doc);
      if (appointment.status === AppointmentStatus.scheduled && appointment.recurrence_id) {
        appointments.push(appointment);
      }
    });

    return appointments;
  }
  static async searchUpcomingByCustomer(customerId: string, date = new Date(), withLimit = 50) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("customer.id", "==", customerId),
        where("start_time", ">", date),
        where("status", "==", AppointmentStatus.scheduled),
        orderBy("start_time", "asc"),
        limit(withLimit)
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchUpcomingByGroomer(groomerId: string, date = new Date(), withLimit = 50) {
    const snapshot = await getDocs(
      query(appointmentsCollection,
        where("groomer.id", "==", groomerId),
        where("start_time", ">", date),
        where("status", "==", AppointmentStatus.scheduled),
        orderBy("start_time", "asc"),
        limit(withLimit)
      )
    );
    return snapshot.docs.map(x => mapEntity(x));
  }
  static async searchFromOnlineBooking() {
    const fromDate = new Date();
    const filters = [
      getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled)
    ];
    const appointments = await CallableFunctions.public.getCustomerAppointments(filters, 10);
    appointments.sortByFieldAscending(x => x.start_time);
    return appointments.filter(x => x.start_time > fromDate);
  }
  static async searchPreviousFromOnlineBooking() {
    const toDate = new Date();
    const filters = [
      getFilter("status", FilterOperator.equal, AppointmentStatus.completed)
    ];
    const appointments = await CallableFunctions.public.getCustomerAppointments(filters);
    appointments.sortByFieldDescending(x => x.start_time);
    return appointments.filter(x => x.start_time < toDate);
  }
  static async searchPastOccurrences(recurrenceIds: string[]) {
    const toDate = new Date();
    const fromDate = getDatePlusDays(toDate, -(8 * 7 * 3), TimeZone.PacificTime);
    return new Promise<Appointment[]>(resolveAll => {
      if (!recurrenceIds.length) return resolveAll([]);

      const batches: Promise<Appointment[]>[] = [];

      while (recurrenceIds.length) {
        const batch = recurrenceIds.splice(0, MAX_IN_PARAMETERS_LENGTH);

        batches.push(
          new Promise(resolve => {
            const customQuery = query(appointmentsCollection,
              where("start_time", "<", toDate),
              where("start_time", ">", fromDate),
              where("status", "==", AppointmentStatus.completed),
              where("recurrence_id", "in", [...batch]),
              orderBy("start_time", "desc")
            );
            getDocs(customQuery).then(results =>
              resolve(results.docs.map(result => mapEntity(result)))
            );
          })
        );
      }

      Promise.all(batches).then(content => {
        resolveAll(content.flat());
      });
    });
  }
  static listenChanges(id: string, callback: (data: Appointment) => void) {
    const reference = doc(appointmentsCollection, id);
    return onSnapshot(reference, snapshot => {
      if (!snapshot.exists())
        return;
      callback(mapEntity(snapshot));
    });
  }
  static listenForCustomer(customerId: string, callback: (appointments: Appointment[]) => void): () => void {
    return onSnapshot(query(appointmentsCollection, where("customer.id", "==", customerId)), snapshot => {
      const appointments: Appointment[] = [];
      snapshot.forEach(x => {
        appointments.push(mapEntity(x));
      });
      callback(appointments);
    });
  }
  static listenFromCalendar(day: LocalDate, isForAllHubs: boolean, callback: (appointments: Appointment[]) => void): () => void {

    const filters = [
      getFilter("start_time", FilterOperator.greaterThanOrEqual, day.toDayStart()),
      getFilter("start_time", FilterOperator.lessThanOrEqual, day.toDayEnd())
    ];

    if (!isForAllHubs) {
      filters.push(
        getFilter("time_zone", FilterOperator.equal, day.contextTimeZone)
      );
    }

    let customQuery = query(appointmentsCollection);
    filters.forEach(filter => {
      customQuery = query(customQuery, where(filter.field, filter.operator, filter.value));
    });

    return onSnapshot(customQuery, snapshot => {
      const appointments: Appointment[] = [];
      snapshot.forEach(x => {
        const entity = mapEntity(x);
        if (Appointment.calendarStatuses().includes(entity.status))
          appointments.push(entity);
      });
      callback(appointments);
    });
  }
  static listenFromGroomerCalendar(day: LocalDate, groomerId: string, callback: (appointments: Appointment[]) => void): () => void {

    const filters = [
      getFilter("start_time", FilterOperator.greaterThanOrEqual, day.toDayStart()),
      getFilter("start_time", FilterOperator.lessThanOrEqual, day.toDayEnd()),
      getFilter("time_zone", FilterOperator.equal, day.contextTimeZone),
      getFilter("groomer.id", FilterOperator.equal, groomerId)
    ];

    let customQuery = query(appointmentsCollection);
    filters.forEach(filter => {
      customQuery = query(customQuery, where(filter.field, filter.operator, filter.value));
    });

    return onSnapshot(customQuery, snapshot => {
      const appointments: Appointment[] = [];
      snapshot.forEach(x => {
        const entity = mapEntity(x);
        if (Appointment.calendarStatuses().includes(entity.status))
          appointments.push(entity);
      });
      callback(appointments);
    });
  }
  static getColorStyles(isPersonalEvent?: boolean, status?: string, paymentStatus?: string, hasServiceArrivalTime?: boolean, isCheckedIn?: boolean): { backgroundColor?: string, color: string, background?: string } {
    const scheduledColor = hasServiceArrivalTime || isCheckedIn
      ? calendarEventStyles.lightBlueAccent
      : calendarEventStyles.blueBackground;

    const paidColor = status === AppointmentStatus.scheduled ? scheduledColor :
      calendarEventStyles.lightBlueBackground;

    if (isPersonalEvent) {
      return calendarEventStyles.lightGrayBackground;
    }
    else if (paymentStatus && status) {
      switch (paymentStatus) {
        case PaymentStatus.paid:
          return paidColor;
        case PaymentStatus.awaiting_payment:
          return calendarEventStyles.blackBackground;
        case PaymentStatus.bad_debt:
          return calendarEventStyles.redBackground;
        case PaymentStatus.offline_payment:
          return calendarEventStyles.linearGradientBackground;
        default:
          return calendarEventStyles.defaultBackground;
      }
    }
    else {
      switch (status) {
        case AppointmentStatus.scheduled:
          return scheduledColor;
        case AppointmentStatus.completed:
          return calendarEventStyles.blackBackground;
        case AppointmentStatus.pending:
          return calendarEventStyles.yellowBackground;
        case AppointmentStatus.cancelled:
          return calendarEventStyles.lightYellowBackground;
        default:
          return calendarEventStyles.defaultBackground;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApi(serialized: any) {
    const { id, ...data } = serialized;
    return new Appointment(id, data);
  }
}
